import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    addBuyerApi,
    cancelSubscriptionBuyerLeadApi,
    deleteBuyerLeadApi,
    editBuyerLeadApi,
    getAllBuyerLeadsApi,
    getBuyerLeadSettingsApi,
    updateBuyerLeadApi
} from "../../../services/manage-lead/buyerLead";
import {toast} from "react-toastify";
import {buyerFilters} from "../../manage-product/product/edit/content/settings/map";


export const getBuyerLeadSettings = createAsyncThunk('leads/buyer-lead/getBuyerLeadsSettings', async (data, {dispatch}) => {
    const response = await getBuyerLeadSettingsApi();
    return response.response;
});

export const getSelectedBuyerLeadData = createAsyncThunk('leads/buyer-lead/getSelectedBuyerLeadData', async (data, {dispatch})=>{
    const response = await editBuyerLeadApi(data);
    return response.response;
});


export const getAllBuyerLeads = createAsyncThunk('leads/buyer-lead/getAllBuyerLeads', async (data, {dispatch,}) => {
    const response = await getAllBuyerLeadsApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});

export const createBuyerLead = createAsyncThunk('leads/buyer-lead/createBuyerLead', async (data, {dispatch})=>{
    const response = await addBuyerApi(data);
    return response.response;
});

export const addBuyerLead = createAsyncThunk('leads/buyer-lead/addBuyerLeads',async (data, {dispatch})=>{
    const response = await updateBuyerLeadApi(data, data.id);
    return response.response;
});

export const deleteBuyerLead = createAsyncThunk('leads/buyer-lead/deleteBuyerLead',async (data, {dispatch})=>{
    const response = await deleteBuyerLeadApi(data);
    dispatch(getAllBuyerLeads());
    toast.success("Delete buyer lead successfully", {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const cancelSubscriptionBuyerLead = createAsyncThunk(
    'leads/buyer-lead/cancelSubBuyerLead',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await cancelSubscriptionBuyerLeadApi(data);
            dispatch(getAllBuyerLeads());
            toast.success("Buyer lead subscription cancelled successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return response.response;
        } catch (error) {
            toast.error("Failed to cancel buyer lead subscription. Please try again.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

const buyerLeadAdapter = createEntityAdapter({});

export const {selectAll: selectBuyerLead, selectById: selectBuyerLeadById} = buyerLeadAdapter.getSelectors(
    state => state.lead.buyerLead
);

const buyerLeadSlice = createSlice({
    name: 'buyerLead',
    initialState: buyerLeadAdapter.getInitialState({
        id: null,
        buyerTableRow: 0,
        searchText: '',
        dialCode: '',
        saveState:false,
        addressAddDialog: {
            component: 'buyerLead',
            type: 'new',
            props: {
                isOpen: false
            },
            data: null
        },
        settings: {
            countries: [],
            sources:[],
            titles:[],
            styleTimeGaps:[],
            sellingPlan:[],
            lineOfBusiness:[],
            averageSales:[],
            averageSpends:[],
            businessModels:[],
            designations:[],
            manufacturingPatterns:[],
            womenCategories:[],
            menCategories:[],
            targetAudience:[],
            childCategories:[],
            giftCategories:[],
            customerRepresentative:[],
        },
        filter: buyerFilters(),

        data: null
    }),
    reducers: {
        setSettings: (state, action) => {
            state.settings.countries = action.payload.countries;
            state.settings.sources = action.payload.sources;
            state.settings.titles = action.payload.titles;
            state.settings.styleTimeGaps = action.payload.styleTimeGap;
            state.settings.sellingPlan = action.payload.sellingPlan;
            state.settings.lineOfBusiness = action.payload.lineOfBusiness;
            state.settings.averageSales = action.payload.averageSales;
            state.settings.averageSpends = action.payload.averageSpends;
            state.settings.designations = action.payload.designations;
            state.settings.manufacturingPatterns = action.payload.manufacturingPatterns;
            state.settings.womenCategories = action.payload.womenCategories;
            state.settings.menCategories = action.payload.menCategories;
            state.settings.childCategories = action.payload.childCategories;
            state.settings.giftCategories = action.payload.giftCategories;
            state.settings.targetAudience = action.payload.targetAudience;
            state.settings.businessModels = action.payload.businessModels;
            state.settings.customerRepresentative = action.payload.customerRepresentative;
        },
        setBuyerFilter: (state, action) => {
            state.filter = action.payload
        },
        setTableRowCount: (state, action) => {
            state.buyerTableRow = action.payload;
        },
        setDialCodeValue: (state, action) => {
            state.dialCode = action.payload;
        },
        openNewAddressDialog: (state, action) => {
            state.addressAddDialog = {
                component: 'buyerLead',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditAddressDialog: (state, action) => {
            state.addressAddDialog = {
                component: 'buyerLead',
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload.data
            };
        },
        closeNewAddressDialog: (state, action) => {
            state.addressAddDialog = {
                component: 'buyerLead',
                type: 'edit',
                props: {
                    isOpen: false
                },
                data: null
            }
        },
        setSaveState: (state, action) => {
            state.saveState = action.payload
        }
    },
    extraReducers: {
        [getAllBuyerLeads.fulfilled]: buyerLeadAdapter.setAll
    }
});

export const {setSettings, setBuyerFilter,setTableRowCount, setDialCodeValue, openNewAddressDialog, closeNewAddressDialog, openEditAddressDialog, setSaveState} = buyerLeadSlice.actions;

export default buyerLeadSlice.reducer