import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { downloadOrderInvoice, downloadOrderPdf, unSetTrackingData } from "../../store/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatAsPrice, numericValue } from "../../../../utils/common";

export const Item = (props) => {
  const { orderState, orderId, retrieveTrackingDetails, authData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const userState = useSelector(({ authReducer }) => authReducer.data);
  const [invoiceLoadingIcon, setInvoiceLoadingIcon] = useState(false);
  let orderSubTotal = 0;
  let shippingDiscount = 0;
  const [discountsTotal, setDiscountsTotal] = useState({});
  let quantityTotal = 0;
  let vat = 0;
  let refundTotal = 0;
  //let discountsTotal = {};

  const processPDFDownload = (name) => {
    const data = {
      id: orderId,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    dispatch(downloadOrderPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${orderState.data ? orderState.data.order_name : "-"}_${name.replace(/\s+/g, "-")}_${new Date().toJSON().slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  const handlePackageSlipDownload = () => {
    processPDFDownload("Package Slip");
  };
  const handlePickingTicketDownload = () => {
    processPDFDownload("Picking Ticket");
  };
  const handleInvoiceDownload = () => {
    if (invoiceLoadingIcon) return;
    setInvoiceLoadingIcon(true);
    const data = {
      id: orderId,
      params: {
        seller: authData.defaultSeller?.value,
      },
    };
    dispatch(downloadOrderInvoice(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${orderState.data ? orderState.data.order_name : "-"}_Invoice_${new Date().toJSON().slice(0, 10)}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      setInvoiceLoadingIcon(false);
    });
  };
  const noTracking = () => {
    dispatch(unSetTrackingData());
    toast.warning("Tracking details are required to be added to this item", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getDiscountDetails = (discount_allocations) => {
    let result = discount_allocations.map((allocation) => {
      let discount_application = orderState.data.discount_applications[allocation.discount_application_index];

      if (discount_application.target_selection === "entitled") {
        let value =
          discount_application.value_type === "percentage"
            ? `${parseFloat(discount_application.value).toFixed(1).replace(/\.0$/, "")}%`
            : `£${allocation.amount_set.shop_money.amount}`;
        let code = discount_application.code ? ` (${discount_application.code})` : ` (${discount_application.title})`;

        return value + code + " (-£" + allocation.amount + ")";
      }
      return null;
    });

    const resultWithBr = result.filter((item) => item !== null); // Filter out null values

    if (resultWithBr && resultWithBr.length > 0) {
      resultWithBr.map((item) => (
        <>
          {item}
          <br />
        </>
      ));
      return (
        <div className="row">
          <p>
            <strong>Discount: </strong>&nbsp;{resultWithBr}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  const hasEntitledDiscount = (discount_allocations) => {
    return discount_allocations.some((allocation) => {
      let discount_application = orderState.data.discount_applications[allocation.discount_application_index];
      return discount_application.target_selection === "entitled";
    });
  };

  const sumDiscountAllocationAmountsCodeWise = () => {
    let updatedDiscountsTotal = {};
    if (orderState.list.length > 0) {
      orderState.list.forEach((item, index) => {
        if (item.discount_allocations && item.discount_allocations.length > 0) {
          item.discount_allocations.forEach((allocation) => {
            let discount_application = orderState.data.discount_applications[allocation.discount_application_index];
            if (discount_application.target_selection == "entitled") {
              let discount = allocation.amount / item.quantity;

              let code = discount_application.code ? discount_application.code : discount_application.title;

              updatedDiscountsTotal[code] = (updatedDiscountsTotal[code] || 0) + discount;
            }
          }, 0);
        }
      });
    }
    return updatedDiscountsTotal;
  };

  const sumDiscountAllocationAmounts = (item) => {
    if (item.discount_allocations === undefined) return 0;

    return item.discount_allocations.reduce((total, allocation) => {
      let discount_application = orderState.data.discount_applications[allocation.discount_application_index];
      if (discount_application.target_selection == "entitled") {
        let discount = allocation.amount / item.quantity;
        return total + discount;
      }
      return total;
    }, 0);
  };

  const getPrice = (item, type) => {
    let price = parseFloat(item.price);
    let total_price = parseFloat(item.total_price_numeric);
    let discount = parseFloat(sumDiscountAllocationAmounts(item));

    if (item.discount_allocations && hasEntitledDiscount(item.discount_allocations)) {
      if (type == "subtotal") {
        let totalPrice = price - discount;
        let formattedPrice = `£${totalPrice.toFixed(2)}`;
        formattedPrice = (
          <>
            <span style={{ textDecoration: "line-through" }}>£{price.toFixed(2)}</span> <span>{formattedPrice}</span>
          </>
        );
        return formattedPrice;
      } else {
        return `£${(total_price - discount * item.quantity).toFixed(2)}`;
      }
    } else {
      if (type == "subtotal") {
        return `£${price.toFixed(2)}`;
      } else {
        return `£${total_price.toFixed(2)}`;
      }
    }
  };

  function calculateShippingVAT(grossAmount, vatPercentage) {
    let vatExcluded = grossAmount / (1 + vatPercentage);
    let vatAmount = Math.round((grossAmount - vatExcluded) * 100) / 100;

    return vatAmount;
  }

  function handleReferenceOrder(refObj) {
    history.push(`/order/${refObj.reference_id}/view`);
  }

  function handleCheckExchangeOrder(excObj) {
    history.push(`/order/${excObj}/view`);
  }

  function getGrandTotal(quantityTotal, refundTotal) {
    let grantTotal =
      numericValue(orderState.data.total_price) -
      numericValue(orderState.data.shipping_charges) +
      parseFloat(quantityTotal * orderState.data.per_item_shipping);
    grantTotal = grantTotal - refundTotal;
    return formatAsPrice(grantTotal);
  }

  useEffect(() => {
    const updatedDiscountsTotal = sumDiscountAllocationAmountsCodeWise();
    setDiscountsTotal(updatedDiscountsTotal);
  }, [orderState]);

  const getDiscountItems = () => {
    let discountItems = [];

    if (orderState.data && orderState.data.total_discount && orderState.data.total_discount > 0 && orderState.data.discount_applications.length > 0) {
      orderState.data.discount_applications.forEach((discount) => {
        if (discount.target_selection === "all") {
          let discountAmount = 0;
          let value =
            discount.value_type === "percentage"
              ? `${parseFloat(discount.value).toFixed(1).replace(/\.0$/, "")}%`
              : `£${parseFloat(discount.value).toFixed(2)}`;

          if (discount.value_type == "percentage") {
            if (discount.target_type == "shipping_line") {
              if (userState.user.role.name == "Seller") {
                return null;
              }
              discountAmount = (parseFloat(orderState.data.shipping_charges) * parseFloat(discount.value)) / 100;
              discountAmount = parseFloat(discountAmount).toFixed(2);
              shippingDiscount += discountAmount;
            } else {
              discountAmount = (orderSubTotal * parseFloat(discount.value)) / 100;
              discountAmount = discountAmount.toFixed(2);
            }
          } else {
            discountAmount = parseFloat(discount.value).toFixed(2);
          }

          if (discountAmount > 0) {
            discountItems.push({
              title_1: discount.code ? discount.code + " (" + value + ")" : discount.title + " (" + value + ")",
              title_2: discount.target_type == "shipping_line" ? "Shipping Discount" : "Order level discount",
              discountAmount: discountAmount,
            });
          }
        } else {
          let discountCode = discount.code ? discount.code : discount.title;
          let discountValue = 0;
          if (discountsTotal.hasOwnProperty(discountCode)) {
            discountValue = discountsTotal[discountCode];
            discountValue = discountValue.toFixed(2);
          }

          if (discountValue > 0) {
            discountItems.push({
              title_1: discountCode,
              title_2: "Order Item specific Discount",
              discountAmount: discountValue,
            });
          }
        }
      });
    }

    return discountItems;
  };

  const [tooltipOpen, setTooltipOpen] = useState({});

  // Toggle function to handle tooltip visibility
  const toggle = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const displayItemQty = (item) => {
    return (
      <>
        {item.quantity != item.actual_available_qty ? (
          <>
            <span className="font-weight-bold cursor-default" id={`TooltipItemAvaQty-${item.id}`}>
              {item.actual_available_qty}
            </span>
            <span className="px-2">/</span>
            <span
              className="font-weight-bold cursor-default"
              style={{ textDecoration: "line-through", textDecorationColor: "red" }}
              id={`TooltipItemQty-${item.id}`}
            >
              {item.quantity}
            </span>
          </>
        ) : (
          <>
            <span className="font-weight-bold cursor-default" id={`TooltipItemQty-${item.id}`}>
              {item.quantity}
            </span>
          </>
        )}

        {item.quantity != item.actual_available_qty && (
          <Tooltip
            isOpen={tooltipOpen[`avaqty_${item.id}`] || false}
            toggle={() => toggle(`avaqty_${item.id}`)}
            target={`TooltipItemAvaQty-${item.id}`}
          >
            Available Qty: {item.actual_available_qty}
          </Tooltip>
        )}

        <Tooltip isOpen={tooltipOpen[`qty_${item.id}`] || false} toggle={() => toggle(`qty_${item.id}`)} target={`TooltipItemQty-${item.id}`}>
          Ordered Qty: {item.quantity}
        </Tooltip>
      </>
    );
  };

  return (
    <Fragment>
      <Col sm="12">
        <Card className="ribbon-wrapper">
          <CardBody>
            <div className="ribbon ribbon-clip h5 ribbon-dark">Items from Order {orderState.data ? orderState.data.order_name : "-"}</div>
            {orderState?.reference_detail && (
              <div className="position-absolute" style={{ top: "14px", right: "12px", fontWeight: 600, color: "#000000" }}>
                Reference{" "}
                <button onClick={() => handleReferenceOrder(orderState?.reference_detail)} style={{ color: "#007bff", background: "transparent" }}>
                  {orderState?.reference_detail?.reference_order_name}
                </button>{" "}
              </div>
            )}
            {orderState.data &&
              orderState.data.risk_level !== "LOW" &&
              (orderState.data.fulfillment_status != "fulfilled" ? (
                <div className="text-danger">
                  <strong>Note:</strong> This order has been flagged as possibly fraudulent. Please do not process and contact customer service at{" "}
                  <a href="mailto:info@goddiva.co.uk">info@goddiva.co.uk </a> as soon as possible.
                </div>
              ) : null)}
            {orderState.data
              ? orderState.data.customer_name !== "-" && (
                  <div className="mt-2 d-flex justify-content-end">
                    <div className="p-2">
                      <Link className="btn btn-primary btn-block" to={`${process.env.PUBLIC_URL}/order/${orderId}/return-items`}>
                        Refund
                      </Link>
                    </div>
                    <div className="p-2">
                      <button
                        onClick={handleInvoiceDownload}
                        className={invoiceLoadingIcon ? "btn btn-success btn-block disabled" : "btn btn-success btn-block"}
                      >
                        {invoiceLoadingIcon ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : "Invoice"}
                      </button>
                    </div>
                    {currentUser.permission.includes("Create Packing Slips") && (
                      <div className="p-2">
                        <button onClick={handlePackageSlipDownload} className="btn btn-danger btn-block">
                          Packing Slip
                        </button>
                      </div>
                    )}
                    {currentUser.permission.includes("Create Picking Ticket") && (
                      <div className="p-2">
                        <button onClick={handlePickingTicketDownload} className="btn btn-primary btn-block">
                          Picking Ticket
                        </button>
                      </div>
                    )}
                  </div>
                )
              : ""}

            <div className="table-responsive">
              <Table>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ whiteSpace: "nowrap", width: "40%" }}>
                      Product
                    </th>
                    <th scope="col" style={{ whiteSpace: "nowrap", width: "15%" }}>
                      Status
                    </th>
                    <th scope="col" style={{ whiteSpace: "nowrap", width: "15%" }}>
                      Return Status
                    </th>
                    <th scope="col" style={{ whiteSpace: "nowrap", width: "10%", textAlign: "center" }}>
                      Quantity
                    </th>
                    <th scope="col" style={{ whiteSpace: "nowrap", width: "10%", textAlign: "right" }}>
                      Price
                    </th>
                    <th scope="col" style={{ whiteSpace: "nowrap", width: "10%", textAlign: "right" }}>
                      Total
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {orderState.list.length > 0
                    ? orderState.list.map((item, index) => {
                        let subTotal = getPrice(item);
                        let withoutCurrency = parseFloat(subTotal.replace(/[£]/g, "").replace(/,/g, ""));
                        orderSubTotal += withoutCurrency;
                        quantityTotal += item.quantity;
                        //vat += item.unit_tax * item.quantity;
                        vat += item.total_tax;
                        const exchangeOrder = orderState.exchange_order_details[item.line_item_id];
                        return (
                          <tr key={"item_" + item.id}>
                            <td className="col-2">
                              <div className="row">
                                <div className="col-md-2">
                                  <img width="40" className="img-fluid" src={item.image} />
                                </div>
                                <div className="col-md-9">
                                  <div className="row">
                                    <p className={"font-primary"}>{item.sku}</p>
                                  </div>
                                  <div className="row">
                                    {(() => {
                                      if (item.status_id === 1) {
                                        return (
                                          <i
                                            onClick={noTracking}
                                            style={{ margin: "auto", marginLeft: 0 }}
                                            className="fa fa-truck mr-1 text-primary"
                                          ></i>
                                        );
                                      } else if (item.status_id === 2) {
                                        return (
                                          <a
                                            onClick={() => {
                                              retrieveTrackingDetails(item.id);
                                            }}
                                          >
                                            <i className="fa fa-truck mr-1 text-success"></i>
                                          </a>
                                        );
                                      } else {
                                        return <i className="fa fa-truck mr-1 text-dark"></i>;
                                      }
                                    })()}
                                    {item.name}
                                  </div>
                                  <div className="row">
                                    <p>
                                      <strong>Brand: </strong>
                                      {item.brand}
                                    </p>
                                  </div>
                                  <div className="row">
                                    <p>
                                      <strong>Color: </strong>
                                      {item.colour}
                                    </p>
                                  </div>
                                  <div className="row">
                                    <p>
                                      <strong>Size: </strong>
                                      {item.size}
                                    </p>
                                  </div>
                                  {item.discount_allocations && item.discount_allocations.length > 0 && getDiscountDetails(item.discount_allocations)}
                                  {exchangeOrder && (
                                    <div className="row">
                                      <button
                                        style={{
                                          background: "transparent",
                                          fontWeight: 600,
                                          color: "#007bff",
                                          textAlign: "left",
                                          paddingLeft: "0px",
                                        }}
                                        onClick={() => handleCheckExchangeOrder(exchangeOrder.order_id)}
                                      >
                                        Exchange Order {exchangeOrder.order_name}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style={{ marginTop: "2rem" }}>{item.status}</div>
                            </td>
                            <td>
                              <div style={{ marginTop: "2rem" }}>{item.return_status}</div>
                            </td>
                            <td>
                              <div style={{ marginTop: "2rem", textAlign: "center" }}>{displayItemQty(item)}</div>
                            </td>
                            <td>
                              <div style={{ marginTop: "2rem", textAlign: "right" }}>{getPrice(item, "subtotal")}</div>
                            </td>
                            <td>
                              <div style={{ marginTop: "2rem", textAlign: "right" }}>£{formatAsPrice(subTotal)}</div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </div>

            <Row>
              <Col md={{ offset: 6, size: 6 }}>
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Sub Total:</td>
                      <td colSpan={2}>
                        {orderState.list.length} items (Total Quantity: {quantityTotal})
                      </td>
                      <td>
                        <div style={{ textAlign: "right" }}>£{formatAsPrice(orderSubTotal)}</div>
                      </td>
                    </tr>

                    {getDiscountItems().map((discount, discountIndex) => {
                      return (
                        <tr key={"discount_" + discountIndex}>
                          <td className="font-weight-bold">{discountIndex == 0 ? "Discount:" : ""}</td>
                          <td colSpan={2}>
                            {discount.title_1}
                            <br />
                            <strong>
                              <span className="small">({discount.title_2})</span>
                            </strong>
                          </td>
                          <td>
                            <div style={{ textAlign: "right" }}>-£{formatAsPrice(discount.discountAmount)}</div>
                          </td>
                        </tr>
                      );
                    })}

                    {userState.user.role.name !== "Seller" ? (
                      <tr>
                        <td className="font-weight-bold">Shipping:</td>
                        <td colSpan={2}></td>
                        <td>
                          <div style={{ textAlign: "right" }}>
                            £{orderState.data ? formatAsPrice(quantityTotal * orderState.data.per_item_shipping) : "0.00"}
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td className="font-weight-bold">Tax:</td>
                      <td colSpan={2}>
                        {orderState.data && orderState.data.tax_rate > 0 ? "VAT (" + orderState.data.tax_rate * 100 + "%) (Included)" : ""}{" "}
                      </td>
                      <td>
                        <div style={{ textAlign: "right" }}>
                          £
                          {orderState.data
                            ? formatAsPrice(
                                vat +
                                  calculateShippingVAT(quantityTotal * orderState.data.per_item_shipping - shippingDiscount, orderState.data.tax_rate)
                              )
                            : "0.00"}
                        </div>
                      </td>
                    </tr>

                    {orderState.refunds.map((refund, refundIndex) => {
                      refundTotal += refund.total_refund_amount;

                      return (
                        <tr key={"refund_" + refundIndex}>
                          <td className="font-weight-bold">{refundIndex == 0 ? "Refund:" : ""}</td>
                          <td colSpan={2}>{refund.reason ? refund.reason : ""} </td>
                          <td>
                            <div style={{ textAlign: "right" }}>-£{formatAsPrice(refund.total_refund_amount)}</div>
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td className="font-weight-bold">Total:</td>
                      <td colSpan={2}></td>
                      <td className="font-weight-bold">
                        <div style={{ textAlign: "right" }}>£{orderState.data ? getGrandTotal(quantityTotal, refundTotal) : ""}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
