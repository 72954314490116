import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Badge,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    Label,
    Popover,
    PopoverBody,
    PopoverHeader,
    Row
} from "reactstrap";
import CommonDataTable from "../../share-components/table/commonDataTable";
import {mapPartnerResponseToTable} from "../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../store/withReducer";
// import useNavigate from 'react-router-dom';

import partnerReducer from "./store";

import {Link, useHistory} from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PreLoader from "../../share-components/ui/preLoader";
import SweetAlert from "sweetalert2";
import {
    getPartners,
    selectPartner,
    setStatusVal,
    summaryDialog,
    updatePartnerOrder,
    updateStatus
} from "./store/partnerSlice";
import SortableTable from './content/shortableTable';
import dragula from "react-dragula";
import ReactPaginate from "react-paginate";
import SummaryModal from "./content/summaryModal";
const PopoverBasicItem = (props) => {
    const {id,btntext,Popoverbody} = props;
    const [popover, setPopover] = useState(false)
    const Toggle = () => setPopover(!popover);
    return (
        <>
            <p style={{cursor:"pointer"}} className="example-popover" id={"Popover-" + id}>{btntext} <i className="fa fa-info-circle"></i></p>
            <Popover
                placement="top"
                isOpen={popover}
                target={"Popover-" + id}
                toggle={Toggle}
                trigger="hover"
            >
                <PopoverBody>
                    {Popoverbody}
                </PopoverBody>
            </Popover>
        </>
    );
};

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const Partner = (props) => {
    const dispatch = useDispatch();
    const [page,setPage]=useState(1);
    const [search,setSearch]=useState(null);
    const [loading, isSetLoading] = useState(true);
    let history = useHistory();
    const partnerState = useSelector(({ partner }) => partner.partner);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const partnerData = useSelector(selectPartner);
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const [isActive,setIsActive] = useState('');
    const [status,setStatus] = useState(null);
    const [activeShip, setActiveShip] = useState([]);
    const [dragulaKey, setDragulaKey] = useState(0);

    const dataMenu = [
        {
            type: 0,
            text: 'Partners',
        }
    ];

    useEffect(() => {
        getData();
    }, [dispatch, page, search, status]);

    const getData = () =>{
        const data={
            page:page,
            pageSize:10,
            search:search,
            status:status
        }
        dispatch(getPartners(data)).then(res=>{
            if(!res.error){
                setDragulaKey(dragulaKey + 1);
                isSetLoading(false); 
            }        
        });
    }

    const onEdit = (id) => {
        history.push('partner/'+id+'/edit');
    }
    const onView = (row) => {
        history.push('partner/'+row+'/view');
    }
    const onActiveDeactivate  = (data) => {
        dispatch(updateStatus(data)).then(res => {
            if (!res.error) getData();
        });
        setIsActive('');
    }
    const onAdd = () => {
        history.push('partner/add');
    }

    const statusOptions = [
        {id: 'active', label: 'Active'},
        {id: 'in_active', label: 'In-Active'},
    ]

    const pageChange = (event) => {
        setPage(event.selected + 1)
    }
    let dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
            let sizeMatrixContainer = dragula([componentBackingInstance], {
                isContainer: function (el) {
                    return false; // only elements in drake.containers will be taken into account
                },
                moves: function (el, source, handle, sibling) {
                    return partnerState.status; // elements are always draggable by default
                },
                accepts: function (el, target, source, sibling) {
                    return true; // elements can be dropped in any of the `containers` by default
                },
                invalid: function (el, handle) {
                    return false; // don't prevent any drags from initiating by default
                },
            });
            sizeMatrixContainer.on('drop', (el, target, source, sibling) => {
                const cardOrder = source.childNodes;
                let sizeIds = [];
                cardOrder.forEach(item => {
                    sizeIds.push(item.getAttribute('data-id'))
                });
                const data = {
                    fields: sizeIds
                }
                dispatch(updatePartnerOrder({...data})).then(res => {
                })
            });
        }
    };

    console.log(partnerState.status);

   
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
            <Breadcrumb title="Partners" data={dataMenu}/>
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid">
                    <div className="feature-products">
                        
                        <Card>
                            <Row>
                                <Col sm="12">
                                    <div className='float-sm-right p-3'>
                                        <a onClick={onAdd} className="btn btn-sm btn-primary"> <i className="fa fa-plus"></i> Add Partner</a>
                                    </div>
                                </Col>
                            </Row>  
                        </Card>

                        <Row>
                            <Col sm="6">
                                <Select
                                    className="select2-filter border"
                                    isClearable
                                    cacheOptions
                                    styles={customStyles} placeholder="Select Status" 
                                    getOptionLabel ={(option)=>option.label}
                                    getOptionValue ={(option)=>option.id} 
                                    onChange={(e)=>{
                                        setStatus(e?e.id:null);
                                        // if(){
                                            dispatch(setStatusVal(e && e.id == 'active'?true:false));
                                        // }else{
                                        //     dispatch(setStatusVal(false));
                                        // }
                                        setPage(0);
                                    }} 
                                    options={statusOptions} 
                                />
                            </Col>
                            <Col sm="6">
                                <Form>
                                    <FormGroup className="m-0">
                                        <Input
                                            type="text"
                                            className="form-control"
                                            onChange={(e)=>{setSearch(e.target.value)}}
                                            placeholder="Search partner Name/ Phone"
                                        />
                                        <i className="fa fa-search"></i>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <div className="ui-sortable small-drag" id="draggableMultiple">
                                    <div className='container' style={{maxWidth:'100%'}} ref={dragulaDecorator} key={dragulaKey}>
                                        {/* <div  style={{display: 'flex'}}> */}
                                            {partnerState.partnerData.data && partnerState.partnerData.data.map((item, i) =>
                                            // <div className="col-lg-12 col-md-12 col-sm-12 " key={i}>
                                                    <Card
                                                        className="col-md-12"
                                                        id={item.id}
                                                        data-id={item.id}
                                                        key={i}
                                                        style={{ height: '85px', marginRight: '5px', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
                                                    >
                                                        <CardBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            {/* Left Side - Logo */}
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {partnerState.status && <i className="fa fa fa-hand-paper-o fa-2x pt-2 pr-2"></i>}
                                                                <img
                                                                    src={item.logoImage}
                                                                    alt="logo"
                                                                    style={{ width: '`100px', height: '80px', marginRight: '10px',padding:5 }}
                                                                />
                                                                <div>
                                                                    <h6 style={{ textTransform: 'capitalize', margin: 0 }}>{item.name}</h6>
                                                                    <p style={{ margin: 0 }}>{item.email} | {item.phone_number}</p>
                                                                    <p style={{ margin: 0 }}>{item.chanel_type} | {item.channelName}</p>
                                                                </div>
                                                            </div>

                                                            <div style={{ position: 'absolute', top: '5px', right: '60px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                <button type="button" onClick={()=>{
                                                                    dispatch(summaryDialog({title:item.name,props:{isOpen:true}}))
                                                                }} className="btn btn-default btn-sm">
                                                                   Summary
                                                                </button>

                                                                <Link className="btn btn-primary btn-sm" to={{pathname:process.env.PUBLIC_URL+'/report/finances-summary?partner_id='+item.id+"&partner_name="+item.name}}>View More</Link>
                                                            </div>
                                                            <div style={{ position: 'absolute', top: '5px', right: '15px', display: 'grid', width: '40px', height: '100px', background: '#dfe0e1', textAlign: 'center', borderRadius: '4px' }}>
                                                              <span>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => {
                                                                        onActiveDeactivate({ id: item.id, status: e.target.checked });
                                                                        setIsActive(e.target.checked);
                                                                    }}
                                                                    checked={item.is_active}
                                                                />
                                                              </span>
                                                                <a onClick={() => onEdit(item.id)} style={{ marginTop: '3px' }}>
                                                                    <i className="fa fa-pencil"></i>
                                                                </a>
                                                                <a onClick={() => onView(item.id)} style={{ marginTop: '2px' }}>
                                                                    <i className="fa fa-eye"></i>
                                                                </a>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                            // </div>

                                            )}
                                        {/* </div> */}
                                    </div>
                                </div>
                                <Col md={12}>
                                    <div style={{float: "right", marginTop: 10}}>
                                        <div>
                                            <ReactPaginate
                                                className="pagination"
                                                activeClassName="pagination-active"
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={pageChange}
                                                pageRangeDisplayed={5}
                                                pageCount={partnerState.pageCount}
                                                forcePage={0}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <SummaryModal
                                 state={partnerState}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{partner: partnerReducer}])(Partner);