import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Label, Row, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import PreLoader from "../../../share-components/ui/preLoader";
import Breadcrumb from "../../../layout/breadcrumb";
import SearchFrom from "./content/SearchFrom";
import ScanReturnItemList from "./content/ItemList";

import useSound from "use-sound";
import successSound from '../../../assets/audio/success.mp3';
import alertSound from '../../../assets/audio/alert.mp3';

import {
    getOrderSettings,
    setSettings,
} from "../store/orderSlice";


const dataMenu = [
    {
        type: 0,
        text: 'Order',
        link: '/order'
    },
    {
        type: 1,
        text: 'Scan Return',
        link: ''
    },
];

const ScanReturn = () => {
    const dispatch = useDispatch();
    const fillterInputRef = useRef();

    const returnState = useSelector(({ order }) => order.returnOrder);
    const scanReturnData = returnState.scanReturnData;

    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isAutoReturn, setIsAutoReturn] = useState(true);

    const [playSuccessSound] = useSound(successSound);
    const [playAlertSound] = useSound(alertSound);

    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [dispatch]);

    return (
        <Fragment>
            <Container fluid className="product-wrapper">
                {loading ? <><PreLoader></PreLoader></> : <>
                    <Breadcrumb data={dataMenu} title="Scan Return" />
                    <div className="product-grid">
                        <SearchFrom
                            fillterInputRef={fillterInputRef}
                            buttonDisabled={buttonDisabled}
                            setButtonDisabled={setButtonDisabled}
                            playAlertSound={playAlertSound} />

                        {scanReturnData && scanReturnData.order_id && <>
                            {isAutoReturn && (
                                <Alert color="secondary">
                                    <h4 className="alert-heading">Alert!</h4>
                                    <p>Return authorization not generated.</p>
                                </Alert>
                            )}

                            <ScanReturnItemList
                                fillterInputRef={fillterInputRef}
                                buttonDisabled={buttonDisabled}
                                setButtonDisabled={setButtonDisabled}
                                setIsAutoReturn={setIsAutoReturn}
                                playSuccessSound={playSuccessSound}
                                playAlertSound={playAlertSound} />
                        </>}
                    </div>
                </>}
            </Container>
        </Fragment>
    );
}

export default (ScanReturn)