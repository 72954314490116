import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Row, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import DatePicker from "../../../share-components/ui/DatePicker";
import { DateRangePicker } from "rsuite";
import Breadcrumb from "../../../layout/breadcrumb";
import withReducer from "../../../store/withReducer";
import reportReducer from "../index";
import ImportForm from "./importForm";
import Select from "react-select";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { mapSearchAutoSuggestion } from "../../../utils/mapper";
import { sellerAutoSuggestionApi, sellerBrandSuggestionApi } from "../../../services/manage-product/productService";
import {
  getInventoryReportData,
  importStockLevel,
  setInventoryReportData,
  openImportDialog,
  closeImportDialog,
  exportInventoryHistory,
  getBrands,
  setInventoryReportFilter,
} from "./store/invontoryReportSlice";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapInventoryReportResponseToTable } from "../../../utils/mapper";

const { afterToday } = DateRangePicker;

const InventoryReport = (props) => {
  const authData = useSelector(({ authReducer }) => authReducer);

  const [searchBrand, setSearchBrand] = useState("");
  const [searchStatus, setSearchStatus] = useState({ id: 1, name: "Main Products (Default)" });
  const [sku, setSku] = useState("");
  const [searchSeller, setSearchSeller] = useState(authData ? authData.defaultSeller : "");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dispatch = useDispatch();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const [loading, setLoading] = useState(true);
  const inventoryReportState = useSelector(({ report }) => report.invontoryReport);
  const [inventoryDatas, setInventoryDatas] = useState([]);
  const [exportBtnDisabled, setExportBtnDisabled] = useState(false);

  const statuses = [
    { id: 0, name: "All Products" },
    { id: 1, name: "Main Products (Default)" },
    { id: 2, name: "Only Group Products With Child" },
    { id: 3, name: "Only Group Products Without Child" },
  ];

  //All the Grades colors
  const gradeColors = {
    A: "linear-gradient(90deg, rgb(7, 158, 225) 0%, rgb(46, 185, 245) 100%)",
    B: "linear-gradient(90deg, rgb(106, 66, 233) 0%, rgb(143, 113, 239) 100%)",
    C: "linear-gradient(90deg, rgb(62, 105, 234) 0%, rgb(117, 148, 240) 100%)",
    Other: "linear-gradient(90deg, rgb(234, 62, 105) 0%, rgb(240, 117, 148) 100%)",
  };

  const grades = ["A", "B", "C", "Other"];
  const gradeData = inventoryReportState.quantity.grades.reduce((acc, item) => {
    const grade = item.grade || "Other";

    const totalValue = Number(item.total_value.replace(/,/g, ""));
    const totalRetailValue = Number(item.total_retail_value.replace(/,/g, ""));

    acc[grade] = {
      ...item,
      total_value: totalValue,
      total_retail_value: totalRetailValue,
      total_quantity: Number(item.total_quantity),
    };

    return acc;
  }, {});

  const handleFileInputChange = (event) => {
    setUploadFile(event);
    setSelectedFile(event[0]);
  };

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    // customer: yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    // shipping: yup.object().shape({rate_name: yup.string(),}).required("shipment is required").typeError('shipment is required'),
  });

  const defaultValues = {
    shipping_refund_amount: 0,
    total_refund_amount: 0,
  };

  const { handleSubmit, formState, reset, control, register, setValue, trigger, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (selectedFile) {
      setButtonDisable(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      dispatch(importStockLevel(formData)).then((res) => {
        if (!res.error) {
          const outputFilename = `monthly_inventory_${new Date().toJSON().slice(0, 10)}.csv`;
          const url = window.URL.createObjectURL(new Blob([res.payload]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", outputFilename);
          document.body.appendChild(link);
          link.click();
          dispatch(closeImportDialog());
        }

        setButtonDisable(false);
      });
    } else {
      toast.error("CSV file is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const { errors, isDirty } = formState;

  const dataMenu = [
    {
      type: 0,
      text: "Inventory Report",
    },
  ];

  const getAllInventoryData = () => {
    dispatch(
      getInventoryReportData({
        pageSize: inventoryReportState.filters.pageSize,
        page: inventoryReportState.filters.currentPage,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        sku: sku ? sku : null,
        brand: searchBrand?.value,
        seller: searchSeller?.value,
        status: searchStatus?.id,
      })
    ).then((res) => {
      if (!res.error) {
        setLoading(false);
        setInventoryDatas(mapInventoryReportResponseToTable(res.payload));
      }
    });
  };

  useEffect(() => {
    getAllInventoryData();
  }, [
    dispatch,
    inventoryReportState.filters.pageSize,
    inventoryReportState.filters.currentPage,
    selectedDate,
    searchBrand,
    searchSeller,
    sku,
    searchStatus,
  ]);

  const afterToday = () => {
    return (date) => date.getTime() > new Date().getTime();
  };

  const sellerPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)));
      });
    });

  const pageChange = (event) => {
    dispatch(
      setInventoryReportFilter({
        ...inventoryReportState.filters,
        currentPage: event,
      })
    );
  };

  const pagination = (event) => {
    dispatch(
      setInventoryReportFilter({
        ...inventoryReportState.filters,
        currentPage: 1,
        pageSize: event,
      })
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
      border: "none",
    }),
  };

  const exportFile = () => {
    if (exportBtnDisabled) return;
    setExportBtnDisabled(true);

    dispatch(
      exportInventoryHistory({
        date: moment(selectedDate).format("YYYY-MM-DD"),
        sku: sku ? sku : null,
        brand: searchBrand?.value,
        seller: searchSeller?.value,
        status: searchStatus?.id,
      })
    ).then((res) => {
      const link = document.createElement("a");
      link.href = res.payload;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setExportBtnDisabled(false);
    });
  };

  const closeDialog = () => {
    dispatch(closeImportDialog());
  };

  const tableColumns = [
    {
      name: "Updated At",
      selector: (row) => row.updated_at,
      sortable: false,
      center: false,
    },
    {
      name: "Product Title",
      selector: (row) => row.product_title,
      sortable: false,
      center: false,
    },
    {
      name: "SKU",
      selector: (row) => row.sku,
      sortable: false,
      center: false,
      width: "300px",
    },
    {
      name: "Grade",
      selector: (row) => row.grade,
      sortable: false,
      center: true,
    },
    {
      name: "Seller",
      selector: (row) => row.seller,
      sortable: false,
      center: false,
    },
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: false,
      center: false,
    },
    {
      name: "Price (£)",
      selector: (row) => row.price,
      sortable: false,
      right: true,
      width: "100px",
    },
    {
      name: "Sale Price (£)",
      selector: (row) => row.sale_price,
      sortable: false,
      right: true,
      width: "120px",
    },
    {
      name: "Cost (£)",
      selector: (row) => row.cost,
      sortable: false,
      right: true,
      width: "100px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: false,
      center: true,
      width: "100px",
    },
    {
      name: "Inventory Value (£)",
      selector: (row) => row.inventory_value,
      sortable: false,
      right: true,
      width: "160px",
    },
  ];

  // data provides access to your row data
  const ExpandedComponent = ({ data }) => {
    const childTableColumns = [
      {
        name: "Location ID",
        selector: (row) => row.location_id,
        sortable: false,
        center: false,
      },
      {
        name: "Location Name",
        selector: (row) => row.location_name,
        sortable: false,
        center: false,
      },
      {
        name: "Bin Number",
        selector: (row) => row.bin_number,
        sortable: false,
        center: false,
      },
      {
        name: "Quantity",
        selector: (row) => row.quantity,
        sortable: false,
        center: true,
      },
      {
        name: "Created At",
        selector: (row) => row.created_at,
        sortable: false,
        center: false,
      },
      {
        name: "Updated At",
        selector: (row) => row.updated_at,
        sortable: false,
        center: false,
      },
    ];

    const childData = data.bin_locations;

    return (
      <div className="px-5 pt-3 pb-5 border-bottom">
        <CommonDataTable headerColumns={childTableColumns} data={childData} noAction pagination={false} />
      </div>
    );
  };

  const typePromiseOption = (inputValue, seller) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
        seller_id: seller,
        check_seller_active: true,
      };
      setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)));
      });
    });

  return (
    <Fragment>
      <Breadcrumb title="Inventory Report" data={dataMenu} />
      <Container fluid={true} className="product-wrapper">
        <div className="product-grid">
          <div className="feature-products">
            <Card>
              <Row style={{ justifyContent: "space-between" }}>
                <Col md="3">
                  <div className="dashboard-datepicker p-3">
                    <DatePicker
                      date={selectedDate}
                      dateChange={(date) => {
                        setSelectedDate(date);
                      }}
                      defaultValue={selectedDate}
                      disabledDate={afterToday()}
                    />
                  </div>
                </Col>
                <Col md="9">
                  <div className="float-right p-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        exportFile();
                      }}
                      disabled={exportBtnDisabled}
                    >
                      <i className={exportBtnDisabled ? "fa fa-spinner fa-spin mr-1" : "fa fa-download mr-1"}></i>
                      Export Report
                    </Button>
                  </div>

                  {/* <div className="float-right p-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        setUploadFile(null);
                        setSelectedFile(null);
                        dispatch(openImportDialog());
                      }}
                    >
                      Import Report
                    </Button>
                  </div> */}
                </Col>
              </Row>

              <Row className="item-grade-quantity">
                {inventoryReportState.quantity && inventoryReportState.quantity.grades && (
                  (() => {
                    const validGrades = grades.filter((grade) => (gradeData[grade]?.total_value || 0) > 0);
                    const displayedGrades = validGrades.length > 0 ? validGrades : ["Other"];
  
                    const totalValueSum = displayedGrades.reduce((sum, grade) => sum + (gradeData[grade]?.total_quantity || 0), 0);
  
                    return displayedGrades.map((grade, index) => {
                      const item = gradeData[grade] || { total_quantity: 0, total_value: 0, total_retail_value: 0 };
  
                      const percentage = totalValueSum ? (item.total_quantity / totalValueSum) * 100 : 0;
  
                      return (
                        <Col key={index} md="3" className={`grade-container grade-${grade.toLowerCase()}`}>
                          <div className="grade-wrapper">
                            <div className="grade-info">
                              <div className="grade-icon" style={{ background: gradeColors[grade] }}></div>
                              <div>
                                <p className="grade-title">{grade === "Other" ? "Other" : `${grade}-grade`}{" - "}<strong>{percentage < 10 ? percentage.toFixed(2) : Math.round(percentage)}%</strong></p>
                                <p className="grade-title">
                                  <strong>
                                    Total Qty : <span>{Number(item.total_quantity).toLocaleString()}</span>
                                  </strong>
                                </p>
                                <p className="grade-title">
                                  <strong>
                                    Total Value : <span>£{item.total_value.toLocaleString()}</span>
                                  </strong>
                                </p>
                                <p className="grade-title">
                                  <strong>
                                    Total Retail Value : <span>£{item.total_retail_value.toLocaleString()}</span>
                                  </strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    });
                  })()
                )}
              </Row>

              <Row style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "2px", marginLeft: "45px", marginRight: "25px" }}>
                  {["A", "B", "C", "Other"].map((grade) => {
                    const data = gradeData[grade];
                    if (!data) return null;

                    const totalValueSum = Object.values(gradeData).reduce((sum, item) => sum + item.total_quantity, 0);
                    const percentage = Math.round((data.total_quantity / totalValueSum) * 100);
                    const background = gradeColors[grade] || gradeColors["Other"];

                    return (
                      <div
                        key={grade}
                        style={{
                          height: "30px",
                          maxHeight: "30px",
                          width: `${percentage}%`,
                          background,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#ffffff",
                        }}
                      >
                        <p style={{ marginBottom: 0 }}>{`${percentage >= 4 ? `${percentage}%` : ""}`}</p>
                      </div>
                    );
                  })}
                </div>
              </Row>
            </Card>
            <Row>
              {currentUser.role.id != 5 && (
                <Col md="">
                  <AsyncSelect
                    className="select2-filter"
                    isClearable={true}
                    defaultOptions
                    value={searchSeller}
                    placeholder="Select Seller"
                    loadOptions={sellerPromiseOption}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onClear={(e) => {}}
                    onChange={(e) => {
                      setSearchSeller(e);
                    }}
                  />
                </Col>
              )}

              <Col md="">
                <AsyncSelect
                  cacheOptions={false}
                  key={searchSeller?.value}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable="true"
                  defaultOptions
                  placeholder="Select Brand"
                  loadOptions={(e) => {
                    return typePromiseOption(e, searchSeller?.value);
                  }}
                  value={searchBrand}
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onClear={(e) => {}}
                  onChange={(e) => {
                    setSearchBrand(e);
                  }}
                />
              </Col>

              <Col md="">
                <Select
                  className="select2-filter"
                  // isClearable
                  // cacheOptions
                  styles={customStyles}
                  placeholder="Select status"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  defaultValue={searchStatus}
                  onChange={(e) => {
                    setSearchStatus(e);
                  }}
                  options={statuses}
                />
              </Col>

              <Col md="">
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Search SKU"
                      onChange={(e) => {
                        setSku(e.target.value);
                      }}
                    />
                    <i className="fa fa-search"></i>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row></Row>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <div>
                      <div className="Inventory Report">
                        <CommonDataTable
                          headerColumns={tableColumns}
                          data={inventoryDatas}
                          noAction
                          paginationServer
                          paginationTotalRows={inventoryReportState.inventoryReportTableRow}
                          paginationRowsPerPageOptions={[10, 25, 50, 100]}
                          onChangeRowsPerPage={pagination}
                          onChangePage={pageChange}
                          expandableRows
                          expandableRowsComponent={ExpandedComponent}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <ImportForm
        dialog={({ report }) => report.invontoryReport.importDialog}
        schema={schema}
        onSubmitForm={onSubmit}
        reset={reset}
        formState={formState}
        handleSubmit={handleSubmit}
        setValue={setValue}
        title="Import Products"
        selector="products"
        closeDialog={closeDialog}
        buttonDisable={buttonDisable}
        handleFileInputChange={handleFileInputChange}
        uploadFile={uploadFile}
      />
    </Fragment>
  );
};

export default withReducer([{ report: reportReducer }])(InventoryReport);
