import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {summaryDialog} from "../store/partnerSlice";



export default function SummaryModal(props) {
    const {state}=props;
    const dispatch = useDispatch();


    const closeModalDialog = () => {
        dispatch(summaryDialog({title:"",props:{isOpen:false}}))
    }
     console.log(state.summaryDialog.props)
    return (
        <div className="flex flex-col modal-lg">
            <Modal size="md" {...state.summaryDialog.props} toggle={() => closeModalDialog()}>
                <ModalHeader toggle={() => {
                    closeModalDialog()
                }}>
                    {state.summaryDialog.title}'s Summary

                </ModalHeader>
                <ModalBody>

                   Coming Soon

                </ModalBody>

            </Modal>

        </div>
    );
}
