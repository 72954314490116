import API from "../../utils/api";

export const getAllBuyerLeadsApi = async (data) => {
    const response = await API.get('buyer-lead/buyer-lead', { params: data });
    return { response };
}

export const addBuyerApi = async (BuyerLead) => {
    const response = await API.post('buyer-lead/buyer-lead', BuyerLead);
    return { response };
}

export const updateBuyerLeadApi = async (BuyerLead,id) => {
    const response = await API.post(`buyer-lead/buyer-lead/${id}`, BuyerLead);
    return {response};
}

export const deleteBuyerLeadApi = async (id) => {
    const response = await API.delete(`buyer-lead/buyer-lead/${id}`);
    return {response};
}

export const editBuyerLeadApi = async (id) => {
    const response = await API.get(`buyer-lead/buyer-lead/${id}`);
    return {response};
}

export const getBuyerLeadSettingsApi = async () => {
    const response = await API.get(`buyer-lead/settings`);
    return {response};
}

export const cancelSubscriptionBuyerLeadApi = async (id) => {
    const response = await API.post(`buyer-lead/cancel-subscription/${id}`);
    return {response};
}