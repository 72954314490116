import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import {useDispatch, useSelector} from "react-redux";
import {addPartner, getPartnerSettings, setSettings} from "../store/partnerSlice";
import withReducer from "../../../store/withReducer";
import partnerReducer from "../store";
import Select from "react-select";
import CKEditors from "react-ckeditor-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Switch from "react-switch";
import {editorConfiguration} from "../../../utils/common";
import AsyncSelect from "react-select/async";
import {partnerSelectAutoSuggestion} from "../../../utils/mapper";
import {partnerSelectAutoSuggestionApi} from "../../../services/partner";
import ImageUpload from "image-upload-react";

export const defaultValues = {
    name: '',
    email: '',
    number: '',
    pdCountry: '',
    formats: '',
    isActive: false,
    isFormReturn: false,
    channelId: '',
    channelName: '',
    returnUrl: '',
    logoImage: '',
    imageFileLogo: '',
    logoImageDeleted: '',
    orderedVia: '',
    howMakeReturnUrl: '',
    returnContent: '',
    isPartner: false,
    agreedCommission: '',
    agreedBy: '',
    duration: '',
    accountCheckedEmail: '',
    accountCheckedName: '',
    accountCheckedPhone: '',
    shippingPaidBy: '',
    shippingRule: '',
    shippingCondition: '',
    is_agent: false,
    agentName: '',
    agentEmail: '',
    agentPhone: '',
    agentCommission: '',
    marketplaceName:'',
    marketplaceEmail:'',
    marketplacePhone:'',
    marketplaceJob:'',
    technicalEmail: '',
    technicalName:'',
    technicalPhone:'',
    technicalJob:'',
    returnTo:'',
    returnCostPaid:'',
    returnToWareHouse:'',
    orderCondition:'',
    orderReceived:'',
    manual:'',
    automaticApp:'',
    interval:'',
    shopifyChannel:'',
    mpUserName:'',
    mpEmail:'',
    mpPassword:'',
    accountName:'',
    accountPhone:'',
    accountEmail:'',
    merchdiserName:'',
    merchdiserPhone:'',
    merchdiserEmail:'',




};

/**
 * Form Validation Schema
 */
const phoneRegExp = /^[0-9]{9,10}$/
const phoneRegexFull = /\+?\d{1,3}?[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if (value) {
            return value.match(phoneRegExp) ? true : false;
        }
        return true;
    });
});
yup.addMethod(yup.string, "phoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if (value) {
            return value.match(phoneRegexFull) ? true : false;
        }
        return true;
    });
});
yup.addMethod(yup.string, "returnUrlValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        if (value) {
            return value.includes('$return_url');
        }
        return true;
    });
});
yup.addMethod(yup.string, "howToReturnUrlValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        if (value && this.parent.howMakeReturnUrl) {
            return value.includes('$how_to_return_url');
        }
        return true;
    });
});

export const schema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    returnContent: yup.string().returnUrlValidation('You must include $return_url').howToReturnUrlValidation('You must include $how_to_return_url'),
    formats: yup
        .mixed()
        .when(['channelId'], {
            is: (channelId) => channelId?.id == '1',
            then: yup.object().required('You must select a format.').typeError('Please select Format'),
            otherwise: yup.object().nullable(),
        }),
    number: yup.string().nullable().min(9, 'Invalid Phone number').max(10, 'Invalid Phone number').when({
        is: (val) => val,
        then: yup.string().customPhoneValidation('You must enter valid phone number'),
    }),
    email: yup.string().nullable().email(),
    accountCheckedEmail: yup.string().nullable().email('invalid email'),
    accountCheckedPhone: yup.string().nullable().phoneValidation('Invalid Phone number'),
    agentEmail: yup.string().nullable().email('invalid email'),
    agentPhone: yup.string().nullable().phoneValidation('Invalid Phone number'),
    marketplaceEmail: yup.string().nullable().email('invalid email'),
    marketplacePhone: yup.string().nullable().phoneValidation('Invalid Phone number'),
    technicalEmail: yup.string().nullable().email('invalid email'),
    technicalPhone: yup.string().nullable().phoneValidation('Invalid Phone number'),
    accountPhone:yup.string().nullable().phoneValidation('Invalid Phone number'),
    accountEmail:yup.string().nullable().email('invalid email'),
    mpPhone:yup.string().nullable().phoneValidation('Invalid Phone number'),
    mpEmail:yup.string().nullable().email('invalid email'),
    merchdiserPhone:yup.string().nullable().phoneValidation('Invalid Phone number'),
    merchdiserEmail:yup.string().nullable().email('invalid email'),
    // pdCountry: yup.object().required('Country is required field').typeError('Please select Country'),
    // customer:yup.object().shape({value: yup.string(),}).nullable().required('You must select a customer.'),
    // isActive: yup.bool().nullable(),
    // isFormReturn: yup.bool().nullable(),
});


const AddPartner = (props) => {
    const dataMenu = [
        {
            type: 1,
            text: 'Partners',
            link: '/partner'
        },
        {
            type: 0,
            text: "Add Partner"
        }
    ];
    const {handleSubmit, formState, reset, register, setValue, getValues, trigger} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const [storeDescription, setStoreDescription] = useState('');
    const [sellerDescription, setSellerDescription] = useState('');
    const [otherPolicy, setOtherPolicy] = useState('');
    const [sellerSinceDate, setSellerSinceDate] = useState(new Date());
    const [actionBtn, setActionBtn] = useState(0);
    const [isFormReturn, setIsFormReturn] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isPartner, setIsPartner] = useState(false);
    const [basictooltip, setbasictooltip] = useState(false);
    const {errors} = formState;
    const formRef = useRef();
    const [dialCode, setDialCode] = useState('');
    const submitBtnSaveRef = useRef();
    const submitBtnSaveExitRef = useRef();
    const dispatch = useDispatch();
    let history = useHistory();
    const [partnerFormat, setPartnerFormat] = useState(null);


    const [selectedImageLogo, setSelectedImageLogo] = useState(getValues('imageFileLogo'));
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
    const handleDeleteLogoImage = () => {
        setValue('logoImage', null);
        setValue('imageFileLogo', null);
        setSelectedImageLogo(null);
        console.log('Logo Image deleted');
        const input = document.getElementById("hiddenFileInput2");
        input.value = "true";
    }


    useEffect(() => {
        dispatch(getPartnerSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                console.log("result", results);
                dispatch(setSettings(results));
            }
        });
    }, [dispatch]);

    const partnerState = useSelector(({partner}) => partner.partner);

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 50,
            border: 'none',
        })
    };

    const onSubmitForm = (data) => {
        if (data) {

            const formData = new FormData();

            formData.append('name', data.name ? data.name : "");
            formData.append('email', data.email ? data.email : "");
            formData.append('number', data.number ? data.number : "");
            formData.append('pdCountry', data.pdCountry ? data.pdCountry.id : "");
            formData.append('format', data.formats ? data.formats.id : "");
            formData.append('status', isActive ? 1 : 0);
            formData.append('is_return_form', isFormReturn ? 1 : 0);
            formData.append('channelId', data.channelId ? data.channelId.id : "");
            formData.append('channelName', data.channelName ? data.channelName : "");
            formData.append('returnUrl', data.returnUrl ? data.returnUrl : "");
            formData.append('orderedVia', data.orderedVia ? data.orderedVia : "");
            formData.append('howMakeReturnUrl', data.howMakeReturnUrl ? data.howMakeReturnUrl : "");
            formData.append('returnContent', data.returnContent ? data.returnContent : "");
            formData.append('isPartner', isPartner ? 1 : 0);
            formData.append('logoImage', data.logoImage ? data.logoImage : "");
            formData.append('agreedCommission', data.agreedCommission ? data.agreedCommission : "");
            formData.append('agreedBy', data.agreedBy ? data.agreedBy.id : "");
            formData.append('duration', data.duration ? data.duration.id : "");
            formData.append('accountCheckedEmail', data.accountCheckedEmail ? data.accountCheckedEmail : "");
            formData.append('accountCheckedName', data.accountCheckedName ? data.accountCheckedName: "");
            formData.append('accountCheckedPhone', data.accountCheckedPhone ? data.accountCheckedPhone : "");
            formData.append('shippingPaidBy', data.shippingPaidBy ? data.shippingPaidBy.id : "");
            formData.append('shippingRule', data.shippingRule ? (data.shippingRule).map(item => item.id):'');
            formData.append('shippingCondition', data.shippingCondition ? data.shippingCondition : "");
            formData.append('is_agent', data.is_agent ?1:0);
            formData.append('agentName', data.agentName ?data.agentName:'');
            formData.append('agentEmail', data.agentEmail ?data.agentEmail:'');
            formData.append('agentPhone', data.agentPhone ?data.agentPhone:'');
            formData.append('agentCommission', data.agentCommission ?data.agentCommission:'');
            formData.append('marketplaceName', data.marketplaceName ?data.marketplaceName:'');
            formData.append('marketplaceEmail', data.marketplaceEmail ?data.marketplaceEmail:'');
            formData.append('marketplacePhone', data.marketplacePhone ?data.marketplacePhone:'');
            formData.append('marketplaceJob', data.marketplaceJob ?data.marketplaceJob:'');
            formData.append('technicalEmail', data.technicalEmail ?data.technicalEmail:'');
            formData.append('technicalName', data.technicalName ?data.technicalName:'');
            formData.append('technicalPhone', data.technicalPhone ?data.technicalPhone:'');
            formData.append('technicalJob', data.technicalJob ?data.technicalJob:'');
            formData.append('returnTo', data.returnTo ?data.returnTo:'');
            formData.append('returnCostPaid', data.returnCostPaid ?data.returnCostPaid.id:'');
            formData.append('returnToWareHouse', data.returnToWareHouse ?data.returnToWareHouse:'');
            formData.append('orderCondition', data.orderCondition ? (data.orderCondition).map(item => item.id):'');
            formData.append('orderReceived', data.orderReceived ?data.orderReceived.id:'');
            formData.append('manual', data.manual ?data.manual.id:'');
            formData.append('automaticApp', data.automaticApp ?data.automaticApp.id:'');
            formData.append('interval', data.interval ?data.interval.id:'');
            formData.append('shopifyChannel', data.shopifyChannel ?data.shopifyChannel.id:'');
            formData.append('mpUserName', data.mpUserName ?data.mpUserName:'');
            formData.append('mpEmail', data.mpEmail ?data.mpEmail:'');
            formData.append('mpPassword', data.mpPassword ?data.mpPassword:'');
            formData.append('accountName', data.accountName ?data.accountName:'');
            formData.append('accountPhone', data.accountPhone ?data.accountPhone:'');
            formData.append('accountEmail', data.accountEmail ?data.accountEmail:'');
            formData.append('merchdiserName', data.merchdiserName ?data.merchdiserName:'');
            formData.append('merchdiserPhone', data.merchdiserPhone ?data.merchdiserPhone:'');
            formData.append('merchdiserEmail', data.merchdiserEmail ?data.merchdiserEmail:'');
            if (document.getElementById("hiddenFileInput2").value === "true") {
                formData.append('logoImageDeleted', true);
            } else {
                formData.append('logoImageDeleted', false);
            }

            console.log("Form Submit", data);
            submitBtnSaveRef.current.disabled = true;
            dispatch(addPartner(formData)).then(res => {
                if (!res.error) {
                    reset(defaultValues);
                    history.push('/partner');
                } else {
                    // toast.error(res.error.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    // });
                }
                submitBtnSaveRef.current.disabled = false;
            });
        }
    };

    const handleChange = (nextChecked) => {
        console.log(nextChecked)
        setIsActive(nextChecked);
        // setValue('status',nextChecked)
    };
    const handleAgentChange = (checked) => {
        setValue('is_agent', checked);
        trigger();
    }

    const handleChangeReturnForm = (nextChecked) => {
        console.log(nextChecked)
        setIsFormReturn(nextChecked);
        // setValue('is_return_form',nextChecked)
    };

    const handleChangeBarcode = (nextChecked) => {
        console.log(nextChecked)
        setIsPartner(nextChecked);
    };

    console.log(errors);
    const toggle = () => setbasictooltip(!basictooltip);
    return (
        <Fragment>
            <Breadcrumb title="Add Partner" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form ref={formRef} onSubmit={handleSubmit(onSubmitForm)}>

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Partner Details</p>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Name<span style={{color: "red"}}>*</span></Label>
                                                <input name="name" type="text"
                                                       className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                       placeholder="City Goddess Ltd" {...register('name')}  />
                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="email" type="email"
                                                       className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                       placeholder="john.doe@citygoddess.co.uk" {...register('email')}  />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Country</Label>
                                                <Select placeholder="United  Kingdom"
                                                        getOptionLabel={(option) => option.name}
                                                        defaultValue={getValues('pdCountry')}
                                                        getOptionValue={(option) => option.id} {...register("pdCountry")}
                                                        onChange={(e) => {
                                                            setValue('pdCountry', e);
                                                            trigger('pdCountry');
                                                            setDialCode(e.dial_code)
                                                        }} className={` ${errors.pdCountry ? 'is-invalid' : ''}`}
                                                        options={partnerState.settings ? partnerState.settings.countries : null}/>
                                                <div className="invalid-feedback">{errors.pdCountry?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Phone Number</Label>
                                                <FormGroup className="input-group">
                                                    <span className="input-group-text">{dialCode}</span>
                                                    <input name="number" type="number" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                           className={`form-control ${errors.number ? 'is-invalid' : ''}`}
                                                           placeholder="Phone Number" {...register('number')}  />
                                                    <div className="invalid-feedback">{errors.number?.message}</div>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Format</Label>
                                                <Select
                                                    placeholder="Select Format"
                                                    name="formats"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.formats}
                                                    onChange={(e) => {
                                                        setPartnerFormat({
                                                            formats: e,
                                                        });
                                                        setValue('formats', e);
                                                        trigger('formats');
                                                    }}
                                                    className={` ${errors.formats ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.formats?.message}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col md={1}>
                                            <FormGroup>
                                                <Label className="form-label">Is Active</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false}
                                                            onChange={handleChange} checked={isActive}
                                                            className="react-switch"/>
                                                </div>
                                                <div style={{
                                                    width: '100%',
                                                    marginTop: '0.25rem',
                                                    fontSize: '80%',
                                                    color: '#dc3545'
                                                }}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label className="form-label">Is Return Form</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false}
                                                            onChange={handleChangeReturnForm} checked={isFormReturn}
                                                            className="react-switch"/>
                                                </div>
                                                <div style={{
                                                    width: '100%',
                                                    marginTop: '0.25rem',
                                                    fontSize: '80%',
                                                    color: '#dc3545'
                                                }}>{errors.isPremium?.message}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <Label for="selectOption">Select Channel name</Label>
                                            <InputGroup>
                                                <Select
                                                    placeholder="Select Channel"
                                                    type="select"
                                                    name="channelId"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.channelId}
                                                    onChange={(e) => {
                                                        setValue('channelId', e);
                                                        trigger('channelId');
                                                    }}
                                                    className={` ${errors.channelId ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.channelId?.message}</div>
                                                <input name="channelName" type="text" className='form-control'
                                                       placeholder="channel Name" {...register('channelName')}  />
                                            </InputGroup>

                                        </Col>

                                        <Col md={3}>
                                            <FormGroup>
                                                <Label className="form-label">Ordered Via</Label>
                                                <input name="orderedVia" type="text" className='form-control'
                                                       placeholder="Ordered Via" {...register('orderedVia')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <input name="logoImageDeleted" type="hidden" id="hiddenFileInput2"/>
                                            <FormGroup>
                                                <Label className="form-label">Partner shop Logo</Label>
                                                <span className="mt-3 mr-2 ml-1" id="TooltipExample2">
                                                    <i aria-hidden="true" className="fa fa-exclamation-circle"></i>
                                                </span>
                                                <Tooltip
                                                    placement='right'
                                                    isOpen={tooltipOpen1}
                                                    target="TooltipExample2"
                                                    toggle={toggle1}
                                                    style={{fontSize: '12px', maxWidth: '900px'}}
                                                >
                                                    please upload logo image with width:260px, height:68px
                                                </Tooltip>
                                                <div className="text-black-50">{errors.logoImage?.message}</div>
                                                <div className="text-black-50">{errors.imageFileLogo?.message}</div>
                                                <ImageUpload
                                                    handleImageSelect={e => {
                                                        setValue('logoImage', e.target.files[0]);
                                                        setValue('imageFileLogo', URL.createObjectURL(e.target.files[0]));
                                                        setSelectedImageLogo(URL.createObjectURL(e.target.files[0]));
                                                    }}
                                                    imageSrc={selectedImageLogo}
                                                    setImageSrc={setSelectedImageLogo}
                                                    style={{
                                                        width: 'auto',
                                                        height: '150px',
                                                        marginTop: 0,
                                                        marginLeft: 0,
                                                        backgroundColor: '#3E4A56',
                                                        zIndex: 0
                                                    }}
                                                    deleteIcon={
                                                        <span className="mt-3 mr-2" onClick={handleDeleteLogoImage}>
                                                            <i aria-hidden="true"
                                                               className="fa fa-times-circle fa-2x"></i>
                                                        </span>
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={2}>
                                            <FormGroup>
                                                <Label className="form-label">Is Partner barcode generate</Label>
                                                <div className="form-control pl-0 border-0">
                                                    <Switch uncheckedIcon={false} checkedIcon={false}
                                                            onChange={handleChangeBarcode} checked={isPartner}
                                                            className="react-switch"/>
                                                </div>
                                                <div style={{
                                                    width: '100%',
                                                    marginTop: '0.25rem',
                                                    fontSize: '80%',
                                                    color: '#dc3545'
                                                }}>{errors.is_parent?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Agreed Commission (%)</Label>
                                                <input name="returnUrl" type="number" className='form-control'
                                                       placeholder="20%" {...register('agreedCommission')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Agreed By</Label>
                                                <Select
                                                    placeholder="Select User"
                                                    type="select"
                                                    name="agreedBy"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.users}
                                                    onChange={(e) => {
                                                        setValue('agreedBy', e);
                                                        trigger('agreedBy');
                                                    }}
                                                    className={` ${errors.agreedBy ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.agreedBy?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Payment Details</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Duration</Label>
                                                <Select
                                                    placeholder="Select Duration"
                                                    type="select"
                                                    name="duration"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.durations}
                                                    onChange={(e) => {
                                                        setValue('duration', e);
                                                        trigger('duration');
                                                    }}
                                                    className={` ${errors.duration ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.duration?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="font-weight-bold">Accounts to be checked by</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Name</Label>
                                                <input name="accountCheckedName" type="text" className='form-control'
                                                       placeholder="johin" {...register('accountCheckedName')}  />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="accountCheckedEmail" type="text"
                                                       className={`form-control ${errors.accountCheckedEmail ? 'is-invalid' : ''}`}
                                                       placeholder="jhon@gmail.com" {...register('accountCheckedEmail')}  />
                                                <div
                                                    className="invalid-feedback">{errors.accountCheckedEmail?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label className="form-label">Phone</Label>
                                                <input name="accountCheckedPhone" type="text"
                                                       className={`form-control ${errors.accountCheckedPhone ? 'is-invalid' : ''}`}
                                                       placeholder="+4466999134" {...register('accountCheckedPhone')}  />
                                                <div
                                                    className="invalid-feedback">{errors.accountCheckedPhone?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Shipping Condition Details</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Shipping Paid By</Label>
                                                <Select
                                                    placeholder="Select Paid By"
                                                    type="select"
                                                    name="shippingPaidBy"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.shipping_by}
                                                    onChange={(e) => {
                                                        setValue('shippingPaidBy', e);
                                                        trigger('shippingPaidBy');
                                                    }}
                                                    className={` ${errors.shippingPaidBy ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.shippingPaidBy?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Shipping Rules</Label>
                                                <Select
                                                    placeholder="Select Rules"
                                                    type="select"
                                                    name="shippingRule"
                                                    isMulti
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.shipping_by}
                                                    onChange={(e) => {
                                                        setValue('shippingRule', e);
                                                        trigger('shippingRule');
                                                    }}
                                                    className={` ${errors.shippingRule ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.shippingRule?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Shipping Split Condition</Label>
                                                <input name="shippingSplitCondition" type="text"
                                                       className={`form-control ${errors.shippingSplitCondition ? 'is-invalid' : ''}`}
                                                       placeholder="condition" {...register('shippingSplitCondition')}  />
                                                <div
                                                    className="invalid-feedback">{errors.shippingSplitCondition?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Agent Details</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={1}>
                                            <Col md={1}>
                                                <FormGroup>
                                                    <Label className="form-label">Agent</Label>
                                                    <div className="form-control pl-0 border-0">
                                                        <Switch uncheckedIcon={false} checkedIcon={false}
                                                                onChange={handleAgentChange}
                                                                checked={getValues('is_agent')}
                                                                className="react-switch"/>
                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        marginTop: '0.25rem',
                                                        fontSize: '80%',
                                                        color: '#dc3545'
                                                    }}>{errors.isPremium?.message}</div>
                                                </FormGroup>
                                            </Col>
                                        </Col>
                                    </Row>
                                    {getValues('is_agent') && <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Name</Label>
                                                <input name="agentName" type="text" className='form-control'
                                                       placeholder="johin" {...register('agentName')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Phone</Label>
                                                <input name="agentPhone" type="text"
                                                       className={`form-control ${errors.agentPhone ? 'is-invalid' : ''}`}
                                                       placeholder="+4466999134" {...register('agentPhone')}  />
                                                <div className="invalid-feedback">{errors.agentPhone?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>}
                                    {getValues('is_agent') && <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="agentEmail" type="text"
                                                       className={`form-control ${errors.agentEmail ? 'is-invalid' : ''}`}
                                                       placeholder="jhon@gmail.com" {...register('agentEmail')}  />
                                                <div className="invalid-feedback">{errors.agentEmail?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Commission</Label>
                                                <input name="agentCommission" type="text"
                                                       className={`form-control ${errors.agentCommission ? 'is-invalid' : ''}`}
                                                       placeholder="20" {...register('agentCommission')}  />
                                                <div
                                                    className="invalid-feedback">{errors.agentCommission?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Marketplace Contact Details</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Full Name</Label>
                                                <input name="marketplaceName" type="text" className='form-control'
                                                       placeholder="johin" {...register('marketplaceName')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Telephone</Label>
                                                <input name="marketplacePhone" type="text"
                                                       className={`form-control ${errors.marketplacePhone ? 'is-invalid' : ''}`}
                                                       placeholder="+4466999134" {...register('marketplacePhone')}  />
                                                <div className="invalid-feedback">{errors.marketplacePhone?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="marketplaceEmail" type="text"  className={`form-control ${errors.marketplaceEmail ? 'is-invalid' : ''}`}
                                                       placeholder="john@gmail.com" {...register('marketplaceEmail')}  />
                                                <div className="invalid-feedback">{errors.marketplaceEmail?.message}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Job Designation</Label>
                                                <input name="marketplaceJob" type="text"
                                                       className={`form-control ${errors.marketplaceJob ? 'is-invalid' : ''}`}
                                                       placeholder="Accountant" {...register('marketplaceJob')}  />
                                                <div className="invalid-feedback">{errors.marketplaceJob?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Technical Contact Details</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Full Name</Label>
                                                <input name="technicalName" type="text" className='form-control'
                                                       placeholder="johin" {...register('technicalName')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Telephone</Label>
                                                <input name="technicalPhone" type="text"
                                                       className={`form-control ${errors.technicalPhone ? 'is-invalid' : ''}`}
                                                       placeholder="+4466999134" {...register('technicalPhone')}  />
                                                <div className="invalid-feedback">{errors.technicalPhone?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="technicalEmail" type="text"  className={`form-control ${errors.technicalEmail ? 'is-invalid' : ''}`}
                                                       placeholder="johin@gmailcom" {...register('technicalEmail')}  />
                                                <div className="invalid-feedback">{errors.technicalEmail?.message}</div>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Job Designation</Label>
                                                <input name="technicalJob" type="text"
                                                       className={`form-control ${errors.technicalJob ? 'is-invalid' : ''}`}
                                                       placeholder="Accountant" {...register('technicalJob')}  />
                                                <div className="invalid-feedback">{errors.technicalJob?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Return Details</p>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Return Url</Label>
                                                <input name="returnUrl" type="text" className='form-control'
                                                       placeholder="Return URL" {...register('returnUrl')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">How to make return Url</Label>
                                                <input onBlur={() => {
                                                    if (getValues('returnContent')) {
                                                        trigger('returnContent')
                                                    }
                                                }} name="howMakeReturnUrl" type="text" className='form-control'
                                                       placeholder="How to make return Url" {...register('howMakeReturnUrl')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="form-label">Return Content</Label>
                                                <textarea {...register('returnContent')}

                                                          className={`form-control`} rows={5}></textarea>
                                            </FormGroup>
                                            <div className="invalid-feedback"
                                                 style={{display: "block"}}>{errors.returnContent?.message}</div>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Return to Goddiva</Label>
                                                <input name="returnTo" type="text" className='form-control'
                                                       placeholder="yes" {...register('returnTo')}  />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Return Cost Paid By</Label>
                                                <Select
                                                    placeholder="Select Return Cost Paid By"
                                                    type="select"
                                                    name="returnCostPaid"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.return_costs}
                                                    onChange={(e) => {
                                                        setValue('returnCostPaid', e);
                                                        trigger('returnCostPaid');
                                                    }}
                                                    className={` ${errors.returnCostPaid ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.returnCostPaid?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Return to Marketplace Warehouse</Label>
                                                <input name="returnToWareHouse" type="text" className='form-control'
                                                       placeholder="yes" {...register('returnToWareHouse')}  />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Order Condition  Details</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Order Condition</Label>
                                                <Select
                                                    placeholder="Select Order Condition"
                                                    type="select"
                                                    name="orderCondition"
                                                    isMulti
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.order_conditions}
                                                    onChange={(e) => {
                                                        setValue('orderCondition', e);
                                                        trigger('orderCondition');
                                                    }}
                                                    className={` ${errors.orderCondition ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.orderCondition?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Order Received Via</Label>
                                                <Select
                                                    placeholder="Select Order Received Via"
                                                    type="select"
                                                    name="orderReceived"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.order_receives}
                                                    onChange={(e) => {
                                                        setValue('orderReceived', e);
                                                        trigger('orderReceived');
                                                    }}
                                                    className={` ${errors.orderReceived ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.orderReceived?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Integration Details</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Manual</Label>
                                                <Select
                                                    placeholder="Select Manual"
                                                    type="select"
                                                    name="manual"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.manuals}
                                                    onChange={(e) => {
                                                        setValue('manual', e);
                                                        trigger('manual');
                                                    }}
                                                    className={` ${errors.manual ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.manual?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Shopify Sales Channels</Label>
                                                <Select
                                                    placeholder="Select Channel"
                                                    type="select"
                                                    name="shopifyChannel"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.channels}
                                                    onChange={(e) => {
                                                        setValue('shopifyChannel', e);
                                                        trigger('shopifyChannel');
                                                    }}
                                                    className={` ${errors.shopifyChannel ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.shopifyChannel?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Automatic App</Label>
                                                <Select
                                                    placeholder="Select Automatic App"
                                                    type="select"
                                                    name="automaticApp"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.automatic_app}
                                                    onChange={(e) => {
                                                        setValue('automaticApp', e);
                                                        trigger('automaticApp');
                                                    }}
                                                    className={` ${errors.automaticApp ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.automaticApp?.message}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Stock Update Interval</Label>
                                                <Select
                                                    placeholder="Select Interval"
                                                    type="select"
                                                    name="interval"
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    isClearable
                                                    options={partnerState.settings.intervals}
                                                    onChange={(e) => {
                                                        setValue('interval', e);
                                                        trigger('interval');
                                                    }}
                                                    className={` ${errors.interval ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.interval?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Marketplace Login Details</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">User Name</Label>
                                                <input name="mpUserName" type="text" className='form-control'
                                                       placeholder="johin" {...register('mpUserName')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="mpEmail" type="text"  className={`form-control ${errors.mpEmail ? 'is-invalid' : ''}`}
                                                       placeholder="johin@gmail.com" {...register('mpEmail')}  />
                                                <div className="invalid-feedback">{errors.mpEmail?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Password</Label>
                                                <input name="mpPassword" type="text"  className={`form-control ${errors.mpPassword ? 'is-invalid' : ''}`}
                                                       placeholder="343889900" {...register('mpPassword')}  />
                                                <div className="invalid-feedback">{errors.mpPassword?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Goddiva Account Manager Details</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Name</Label>
                                                <input name="accountName" type="text" className='form-control'
                                                       placeholder="johin" {...register('accountName')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Telephone</Label>
                                                <input name="accountPhone" type="text"  className={`form-control ${errors.accountPhone ? 'is-invalid' : ''}`}
                                                       placeholder="4480006734" {...register('accountPhone')}  />
                                                <div className="invalid-feedback">{errors.accountPhone?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="accountEmail" type="text"  className={`form-control ${errors.accountEmail ? 'is-invalid' : ''}`}
                                                       placeholder="john@gmail.com" {...register('accountEmail')}  />
                                                <div className="invalid-feedback">{errors.accountEmail?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="mb-3" md={12}>
                                            <p className="sub-title font-weight-bold">Goddiva Merchandiser / Stock Control Details</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Name</Label>
                                                <input name="merchdiserName" type="text" className='form-control'
                                                       placeholder="johin" {...register('merchdiserName')}  />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Telephone</Label>
                                                <input name="merchdiserPhone" type="text"  className={`form-control ${errors.merchdiserPhone ? 'is-invalid' : ''}`}
                                                       placeholder="johin" {...register('merchdiserPhone')}  />
                                                <div className="invalid-feedback">{errors.merchdiserPhone?.message}</div>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label className="form-label">Email</Label>
                                                <input name="merchdiserEmail" type="text"  className={`form-control ${errors.merchdiserEmail ? 'is-invalid' : ''}`}
                                                       placeholder="john@gmail.com" {...register('merchdiserEmail')}  />
                                                <div className="invalid-feedback">{errors.merchdiserEmail?.message}</div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="mt-2 d-flex justify-content-end">
                                        <div className="p-2">
                                            <button ref={submitBtnSaveRef} onClick={() => {
                                                formRef.current.submit();
                                                setActionBtn(0)
                                            }} className="btn btn-primary btn-block">Save
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{partner: partnerReducer}])(AddPartner);