import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { sellerBrandSuggestionApi, getHandlingReportApi } from "../../../../services/report";

export const getHandlingReport = createAsyncThunk('report/getHandlingReport', async (data, { dispatch }) => {
    const response = await getHandlingReportApi(data);
    dispatch(setTableRowCount(response.response.count));
    dispatch(setItems(response.response.data));
    dispatch(setTotalSummary(response.response.total_summary));
    return response.response.data;
});

export const getBrands = createAsyncThunk('product/brand-auto-suggestion', async (data, { dispatch, }) => {
    const response = await sellerBrandSuggestionApi(data);
    return response.response;
});

const handlingReportAdapter = createEntityAdapter({});

export const { selectAll: selectHandlingReport, selectById: selectHandlingReportById } = handlingReportAdapter.getSelectors(
    state => state.report
);

const handlingReportSlice = createSlice({
    name: 'handlingReport',
    initialState: handlingReportAdapter.getInitialState({
        id: null,
        tableRow: 0,
        items: [],
        totalSummary: {
            total_beginning_quantity: 0,
            total_added_quantity: 0,
            total_end_quantity: 0,
            total_ordered_quantity: 0,
            total_restocked_quantity: 0,
            total_processed_quantity: 0,
            total_handling_actions: 0,
            total_handling_changes_per_item: 0,
        },
    }),
    reducers: {
        setTableRowCount: (state, action) => {
            state.tableRow = action.payload;
        },
        setTotalSummary: (state, action) => {
            state.totalSummary = action.payload;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
    },
    extraReducers: {
        // [getHandlingReportData.fulfilled]: handlingReportAdapter.setAll
    }
});

export const {
    setTableRowCount,
    setTotalSummary,
    setItems
} = handlingReportSlice.actions;

export default handlingReportSlice.reducer;