import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DateRangePicker } from 'rsuite';

import {
    Card, CardBody, Col, Container,
    Form, FormGroup, Input, Row
} from "reactstrap";

import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import { mapSearchAutoSuggestion } from "../../../../utils/mapper";
import withReducer from "../../../../store/withReducer";

import sellerReducer from "../../store";
import {
    getSellerSettings,
    setSettings, setTableDataCommission
} from "../../store/sellerSlice";
import PreLoader from "../../../../share-components/ui/preLoader";
import { getDetailCommissions } from "../../store/sellerCommissionSlice";
import { sellerAutoSuggestionApi } from "../../../../services/manage-product/productService";
import DatePicker from "../../../../share-components/ui/DateRangePicker";
import { formatterDecimal } from "../../../../utils/common";
const { afterToday } = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const DetailCommission = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const seller_id = queryParams.get("seller");
    const seller_name = queryParams.get("seller_name");
    const start_date = queryParams.get("start_date");
    const end_date = queryParams.get("end_date");

    const selectedSellerOption = {
        label: seller_name,
        value: seller_id
    };

    const dispatch = useDispatch();

    const endDateObj = end_date ? Date.parse(end_date) : new Date();
    const startDateObj = start_date ? Date.parse(start_date) : new Date().setDate(endDateObj.getDate() - 30);

    const [dateRange, setDateRange] = useState([startDateObj, endDateObj]);
    const [startDate, endDate] = dateRange;

    const sellerState = useSelector(({ seller }) => seller.seller);
    const sellerCommission = useSelector(({ seller }) => seller.sellerCommission);
    const userState = useSelector(({ authReducer }) => authReducer.data);

    const [loading, setLoading] = useState(true);
    const [tableLoading, isSetTableLoading] = useState(false);

    const [fillterSeller, setFillterSeller] = useState(seller_id ? selectedSellerOption : null);
    const [fillterAgent, setFillterAgent] = useState(null);

    const [timer, setTimer] = useState(null);
    const [lastFillters, setLastFillters] = useState(null);

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: 10,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        seller_id: fillterSeller ? fillterSeller.value : null,
        agent_id: fillterAgent ? fillterAgent.id : null,
        order_no: null,
    });

    const dataMenu = [
        {
            type: 0,
            text: "Detail Commission"
        }
    ];

    const tableColumns = [
        {
            name: '#',
            selector: row => row.order_name,
            left: true,
            grow: 0.2, // column size
        }, {
            name: 'Date',
            selector: row => row.processed_at,
            left: true,
            grow: 0.3, // column size
        },
        {
            name: 'SKU',
            selector: row => row.sku,
            left: true,
            grow: 0.7, // Larger column
            wrap: true, // Allow text wrapping
        },
        {
            name: 'Product',
            selector: row => row.product_name,
            left: true,
            wrap: true, // Allow text wrapping
        },
        {
            name: 'Type',
            selector: row => row.type,
            left: true,
            grow: 0.2, // column size
        },
        {
            name: 'Qty',
            selector: row => row.quantity,
            center: true,
            grow: 0.1, // column size
        },
        {
            name: 'Gross Amount (£)',
            selector: row => formatterDecimal.format(row.net_amount_exc_vat),
            right: true,
            grow: 0.2, // column size
        },
        {
            name: 'Vat Amount (£)',
            selector: row => formatterDecimal.format(row.vat_amount),
            right: true,
            grow: 0.2, // column size
        },
        {
            name: 'Net Amount (£)',
            selector: row => formatterDecimal.format(row.net_amount),
            right: true,
            grow: 0.2, // column size
        },
        {
            name: 'Commission (%)',
            selector: row => formatterDecimal.format(row.commission_percentage) + "%",
            center: true,
            grow: 0.2, // column size
        }, 
        {
            name: 'Commission (inc Vat) (£)',
            selector: row => formatterDecimal.format(row.commission_amount_inc_vat),
            right: true,
            grow: 0.2, // column size
        }
    ];

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                dispatch(setSettings(results));
            }
        });
    }, [dispatch]);
    
    const getData = () => {
        if (JSON.stringify(filter) == JSON.stringify(lastFillters)) return;

        setLastFillters({ ...filter });
        isSetTableLoading(true);

        dispatch(getDetailCommissions(filter)).then((res) => {
            if (!res.error) {
                setLoading(false);
            }
            isSetTableLoading(false);
        });
    };
    
    useEffect(() => {
        getData();
    }, [dispatch, filter]);

    useEffect(() => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            seller_id: fillterSeller ? fillterSeller.value : null,
            agent_id: fillterAgent ? fillterAgent.id : null,
        }));
    }, [startDate, endDate, fillterSeller, fillterAgent])

    const handleOnChangeOrderNo = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(prev => ({
                ...prev,
                page: 1,
                order_no: e.target.value
            }));
        }, 500);
        setTimer(newTimer)
    };

    const onPageSizeChange = (newPageSize) => {
        setFilter(prev => ({
            ...prev,
            page: 1,
            pageSize: newPageSize === 'All' ? sellerCommission.detailCommissionTableRow : newPageSize
        }));
    }

    const onPageChange = (e) => {
        setFilter({ ...filter, page: e })
    }

    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Detail Commission" data={dataMenu} />
                <Container fluid={true} className="product-wrapper">
                    <div className="product-grid">
                        <div className="feature-products">
                            <Row>
                                <Col sm={userState.user.role.name === "Seller" ? 4 : 3}>
                                    <div className="dashboard-datepicker">
                                        <DatePicker
                                            dateRange={dateRange}
                                            dateRangeChange={(date) => {
                                                setDateRange(date)
                                            }}
                                            defaultValue={[endDateObj, startDateObj]}
                                            disabledDate={afterToday()}
                                        />
                                    </div>
                                </Col>

                                <Col sm={userState.user.role.name === "Seller" ? 4 : 3}>
                                    <Form>
                                        <FormGroup className="m-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={handleOnChangeOrderNo}
                                                placeholder="Order Number / MP Order Number"
                                            />
                                            <i className={tableLoading ? "fa fa-spinner fa-spin" : "fa fa-search"}></i>

                                        </FormGroup>
                                    </Form>
                                </Col>

                                {userState.user.role.name !== "Seller" && (
                                    <Col sm="3">
                                        <AsyncSelect
                                            className="select2-filter"
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            defaultOptions
                                            styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            placeholder="Select Seller"
                                            loadOptions={sellerPromiseOption}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            value={fillterSeller}
                                            onChange={(e) => {
                                                setFillterSeller(e);
                                            }}
                                        />
                                    </Col>
                                )}

                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) && (
                                    <Col sm="3">
                                        <Select
                                            placeholder="Agent/Sales Person"
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={customStyles}
                                            options={sellerState.settings.agents}
                                            onChange={(e) => {
                                                setFillterAgent(e)
                                            }}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive product-table">
                                        <CommonDataTable
                                            headerColumns={tableColumns}
                                            gridData={sellerCommission.detailCommissionLists}
                                            noAction
                                            progressPending={tableLoading}
                                            paginationServer
                                            paginationTotalRows={sellerCommission.detailCommissionTableRow}
                                            paginationRowsPerPageOptions={[10, 50, 100, 500, 1000]}
                                            paginationDefaultPage={filter.page}
                                            paginationPerPage={filter.pageSize}
                                            onChangePage={onPageChange}
                                            onChangeRowsPerPage={onPageSizeChange}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>}
        </Fragment>
    );
}

export default withReducer([{ seller: sellerReducer }])(DetailCommission);