import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import moment from "moment";

import {
	Card,
	Col,
	Container,
	Row,
	Button
} from "reactstrap";

import DateRangePickerPrimary from "../../../share-components/ui/DateRangePickerPrimary";
import Breadcrumb from "../../../layout/breadcrumb";
import withReducer from "../../../store/withReducer";
import reportReducer from "../index";

import SalesSummary from './content/SalesSummary';
import GrossProfitSummary from './content/GrossProfitSummary';
import CheckWithShopify from './content/CheckWithShopify';
import PartnerBreakdown from './content/PartnerBreakdown';
import DeliveryPartners from './content/DeliveryPartners';
import TaxBreakdown from './content/TaxBreakdown';
import CountryBreakdown from './content/CountryBreakdown';
import Payments from './content/Payments';

import {
	getFinancesSummarySetting,
	getFinancesSummary,
	exportFinancesSummary
} from "./store/financesSummarySlice";

import {
	formatterDecimal
} from "../../../utils/common";

import "./styles.scss";
import {useHistory} from "react-router-dom";

const FinancesSummary = (props) => {
	const history = useHistory();
	console.log(history.location.state)
	const financesSummaryState = useSelector(
		({ report }) => report.financesSummary
	);

	const endDateObj = new Date();
	const startDateObj = new Date(endDateObj); // Create a new Date object from endDateObj
	startDateObj.setDate(endDateObj.getDate() - 30); // Subtract 30 days from today

	const [dateRange, setDateRange] = useState([startDateObj, endDateObj]);
	const [startDate, endDate] = dateRange;

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [dateWiseReturn, setDateWiseReturn] = useState(true);
	const [exportBtnDisabled, setExportBtnDisabled] = useState(false);

	const [timer, setTimer] = useState(null);
	const [lastFillters, setLastFillters] = useState(null);
	const [fillterPartner, setFillterPartner] = useState(null);
	const [fillterCountryType, setFillterCountryType] = useState(null);
	const [fillterCurrency, setFillterCurrency] = useState(null);
	const [filters, setFilters] = useState({
		start_date: moment(startDate).format("YYYY-MM-DD"),
		end_date: moment(endDate).format("YYYY-MM-DD"),
		partner: null,
        country_type: fillterCountryType ? fillterCountryType.id : null,
        presentment_currency: fillterCurrency ? fillterCurrency.id : null,
		is_date_wise_return: dateWiseReturn ? 1 : 0
	});

	const dataMenu = [
		{
			type: 0,
			text: "Finances Summary",
		},
	];

	const afterToday = () => {
		return (date) => date.getTime() > new Date().getTime();
	};

	useEffect(() => {
		const queryString = new URLSearchParams(window.location.search)
		const parnterId=queryString.get('partner_id');
		if(parnterId){
			setFillterPartner({id:parnterId,name:queryString.get('partner_name')});
			setDateWiseReturn(1);
		}
		dispatch(getFinancesSummarySetting());
	}, [dispatch]);

	const getFinancesSummaryData = () => {
		if (JSON.stringify(filters) == JSON.stringify(lastFillters)) return;

		setLastFillters({ ...filters });
		setLoading(true);

		dispatch(
			getFinancesSummary({ ...filters })
		).then((res) => {
			if (!res.error) {
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		setFilters({
			...filters,
			start_date: moment(startDate).format("YYYY-MM-DD"),
			end_date: moment(endDate).format("YYYY-MM-DD"),
			partner: fillterPartner ? fillterPartner.id : null,
            country_type: fillterCountryType ? fillterCountryType.id : null,
            presentment_currency: fillterCurrency ? fillterCurrency.id : null,
			is_date_wise_return: dateWiseReturn ? 1 : 0
		});
	}, [startDate, endDate, dateWiseReturn, fillterPartner, fillterCurrency, fillterCountryType])


	useEffect(() => {
		clearTimeout(timer);
		const newTimer = setTimeout(() => {
			getFinancesSummaryData();
		}, 100);
		setTimer(newTimer)
	}, [dispatch, filters])

	const handleCheckboxChangeForDateWiseReturn = () => {
		setDateWiseReturn(!dateWiseReturn);
	};

	const getPercentage = (mainVal, percentageVal) => {
		return percentageVal == 0 || mainVal == 0 ? 0 : formatterDecimal.format(percentageVal * 100 / mainVal);
	}

	const exportFile = () => {
		if (exportBtnDisabled) return;
		setExportBtnDisabled(true);

		dispatch(
			exportFinancesSummary(filters)
		).then((res) => {
			const link = document.createElement("a");
			link.href = res.payload;
			link.setAttribute("download", "");
			document.body.appendChild(link);
			link.click();
			link.remove();
			setExportBtnDisabled(false);
		});
	};

	const generateLink = (type, fillterData) => {
		// Filter out keys with undefined or null values
		var filteredFilters = Object.fromEntries(
			Object.entries(filters).filter(([key, value]) => value !== undefined && value !== null)
		);

		if (fillterData !== undefined && Object.keys(fillterData).length > 0) {
			filteredFilters = {...filteredFilters, ...fillterData};
		}

		const queryString = new URLSearchParams(filteredFilters).toString();

		let url = '';

		if (type == 'gross-sales') {
			url = '/report/finances-summary/details-gross-sales';
		}else if (type == 'discounts') {
			url = '/report/finances-summary/details-discounts';
		}else if (type == 'returns') {
			url = '/report/finances-summary/details-returns';
		}else if (type == 'product-cost') {
			url = '/report/finances-summary/details-product-cost';
		}else if (type == 'shipping-income') {
			url = '/report/finances-summary/details-shipping-income';
		}else if (type == 'return-shipping-income') {
			url = '/report/finances-summary/details-return-shipping-income';
		}else if (type == 'shipping-cost-sales') {
			url = '/report/finances-summary/details-shipping-cost-sales';
		}else if (type == 'shipping-cost-return') {
			url = '/report/finances-summary/details-shipping-cost-return';
		}else if (type == 'commissions') {
			url = '/report/finances-summary/details-commissions';
		}else if (type == 'additional-charges') {
			url = '/report/finances-summary/details-additional-charges';
		}else if (type == 'agent-commission') {
			url = '/report/finances-summary/details-agent-commissions';
		}else if (type == 'taxes') {
			url = '/report/finances-summary/details-taxes';
		}else if (type == 'net-sales') {
			url = '/report/finances-summary/details-net-sales';
		}else if (type == 'payments') {
			url = '/report/finances-summary/details-payments';
		}

		return `${url}?${queryString}`;
	};

    const countryTypes = [
        { 'id': 'uk', 'name': 'UK' },
        { 'id': 'europe', 'name': 'Europe' },
        { 'id': 'international', 'name': 'International' },
    ];

	return (
		<Fragment>
			<Breadcrumb title="Finances Summary" data={dataMenu} />
			<Container fluid={true} className="finances-summary-page">
				<Card className="px-3">
					<Row>
						<Col md="4">
							<div className="p-3">
								<DateRangePickerPrimary
									dateRange={dateRange}
									dateRangeChange={(date) => {
										setDateRange(date);
									}}
									defaultValue={[endDateObj, startDateObj]}
									disabledDate={afterToday()}
								/>
							</div>
						</Col>
						<Col>
							<div className="float-left">
								<div className="checkbox checkbox-primary mt-4 mb-3">
									<input
										id="financesSummaryReturnCalc"
										type="checkbox"
										checked={dateWiseReturn}
										onChange={handleCheckboxChangeForDateWiseReturn}
									/>
									<label htmlFor="financesSummaryReturnCalc">Calculating Date-Wise Returns for Current Date</label>
								</div>
							</div>

							<div className="float-right">
								<Button
									type="button"
									color="primary"
									className="mt-3"
									onClick={() => {
										exportFile();
									}}
									disabled={exportBtnDisabled}
								>
									<i className={exportBtnDisabled ? "fa fa-spinner fa-spin mr-1" : "fa fa-download mr-1"}></i>
									Export Report
								</Button>
							</div>
						</Col>
					</Row>
				</Card>

				<Row>
					<Col md="4" className="pb-4">
						<Select
							className="select2-filter"
							placeholder="Fillter by Partner"
							menuPortalTarget={document.body}
							styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
							value={fillterPartner}
							getOptionValue={(option) => option.id}
							getOptionLabel={(option) => option.name}
							onChange={(e) => {
								setFillterPartner(e);
							}}
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: (props) => {
									return fillterPartner ? null : <components.DropdownIndicator {...props} />;
								}
							}}
							isClearable={true}
							options={financesSummaryState.settings.partners}
						/>
					</Col>

					<Col md="4">
						<Select
							className="select2-filter"
							placeholder="Fillter by Country Type"
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							value={fillterCountryType}
							onChange={(e) => {
								setFillterCountryType(e);
							}}
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: (props) => {
									return fillterCountryType ? null : <components.DropdownIndicator {...props} />;
								}
							}}
							isClearable={true}
							options={countryTypes}
						/>
					</Col>

					<Col md="4">
						<Select
							className="select2-filter"
							placeholder="Fillter by Currency"
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							value={fillterCurrency}
							onChange={(e) => {
								setFillterCurrency(e);
							}}
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator: (props) => {
									return fillterCurrency ? null : <components.DropdownIndicator {...props} />;
								}
							}}
							isClearable={true}
							options={financesSummaryState.settings.currencies}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<Row>
							<Col>
								<SalesSummary
									data={financesSummaryState.data}
									getPercentage={getPercentage}
									loading={loading}
									generateLink={generateLink}
									dateWiseReturn={dateWiseReturn}
									fillterCurrency={fillterCurrency}
								/>
							</Col>
							<Col>
								<GrossProfitSummary
									data={financesSummaryState.data}
									getPercentage={getPercentage}
									loading={loading}
									generateLink={generateLink}
									fillterCurrency={fillterCurrency}
								/>
							</Col>
						</Row>

						<Row>
							<Col>
								<CheckWithShopify
									data={financesSummaryState.data}
									testOrderData={financesSummaryState.test_order_summary}
									loading={loading}
									generateLink={generateLink}
								/>
							</Col>

							{fillterCurrency && fillterCurrency.id != 'GBP' && (
							<Col md="4">
								<Payments 
									data={financesSummaryState.data}
									loading={loading} 
									generateLink={generateLink}
								/>
							</Col>
							)}
						</Row>
					</Col>

					{!(fillterCurrency && fillterCurrency.id != 'GBP') && (
					<Col md="4">
						<Payments 
							data={financesSummaryState.data}
							loading={loading} 
							generateLink={generateLink}
						/>
					</Col>
					)}
				</Row>

				<Row>
					<Col md="12">
						<CountryBreakdown
							data={financesSummaryState.data}
							getPercentage={getPercentage}
							loading={loading}
							generateLink={generateLink}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="12">
						<DeliveryPartners
							items={financesSummaryState.delivery_partners_data}
							getPercentage={getPercentage}
							loading={loading}
							generateLink={generateLink}
							filters={filters}
						/>
					</Col>
				</Row>

				<Row>
					<Col md="12">
						<PartnerBreakdown
							data={financesSummaryState.data}
							getPercentage={getPercentage}
							loading={loading}
							generateLink={generateLink}
						/>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default withReducer([{ report: reportReducer }])(FinancesSummary);
