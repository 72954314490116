import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Form } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Card, CardBody, FormGroup, Input, Label, Nav, Row } from "reactstrap";
import withReducer from "../../../store/withReducer";
import leadReducer from "../store";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapBuyerLeadResponseToTable } from "../../../utils/mapper";
import {
    cancelSubscriptionBuyerLead,
  deleteBuyerLead,
  getAllBuyerLeads,
  getBuyerLeadSettings,
  selectBuyerLead,
  setBuyerFilter,
  setSaveState,
  setSettings,
} from "../store/buyerLeadSlice";
import PreLoader from "../../../share-components/ui/preLoader";
import Select from "react-select";
import { buyerFilters } from "../../manage-product/product/edit/content/settings/map";

const dataMenu = [
  {
    type: 0,
    text: "Leads",
    link: "/leads/buyer-lead",
  },
  {
    type: 1,
    text: "Buyer Leads",
    link: "",
  },
];

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 50,
    border: "none",
  }),
};

const submitTypes = ["submit", "update", "save"];
const BuyerLead = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [differentPage, setDifferentPage] = useState(
    !submitTypes.includes(history.location.state?.from)
  );
  const [timer, setTimer] = useState(null);
  const buyerLead = useSelector(selectBuyerLead);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const buyerLeadState = useSelector(({ lead }) => lead.buyerLead);
  const [loading, isSetLoading] = useState(
    history.location.state?.from !== "update" || buyerLead.length < 1
  );
  const [isFilterable, setIsFilterable] = useState(
    history.location.state?.from !== "update" || buyerLead.length < 1
  );

  useEffect(() => {
    if (!submitTypes.includes(history.location.state?.from)) {
      dispatch(setBuyerFilter(buyerFilters()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFilterable || buyerLeadState.saveState) {
      const filter = buyerLeadState.filter;
      if (
        differentPage &&
        JSON.stringify(filter) != JSON.stringify(buyerFilters())
      ) {
        setDifferentPage(false);
      } else {
        dispatch(
          getAllBuyerLeads({
            page_size: filter.pageSize,
            page: filter.currentPage,
            name: buyerLeadState.filter.name,
            email: buyerLeadState.filter.email,
            pageSize: filter.pageSize,
            mobile: filter.mobile,
            country: buyerLeadState.filter.country
              ? buyerLeadState.filter.country.id
              : "",
            type: buyerLeadState.filter.type
              ? buyerLeadState.filter.type.value
              : "",
            customerRepresentative: buyerLeadState.filter.customerRepresentative
              ? buyerLeadState.filter.customerRepresentative.id
              : "",
            is_vip: filter.is_vip,
          })
        ).then((res) => {
          isSetLoading(false);
        });
        setDifferentPage(false);
      }
      dispatch(setSaveState(false));
    }
    setIsFilterable(true);
  }, [
    buyerLeadState.filter.currentPage,
    buyerLeadState.filter.pageSize,
    buyerLeadState.filter.email,
    buyerLeadState.filter.name,
    buyerLeadState.filter.mobile,
    buyerLeadState.filter.type,
    buyerLeadState.filter.country,
    buyerLeadState.filter.customerRepresentative,
    buyerLeadState.filter.is_vip,
  ]);

  useEffect(() => {
    dispatch(getBuyerLeadSettings()).then((res) => {
      dispatch(setSettings(res.payload));
    });
  }, [dispatch]);

  const onPageSizeChange = (e) => {
    dispatch(
      setBuyerFilter({ ...buyerLeadState.filter, currentPage: 1, pageSize: e })
    );
  };

  const pageChange = (event) => {
    dispatch(setBuyerFilter({ ...buyerLeadState.filter, currentPage: event }));
  };

  const onAdd = () => {
    history.push("/leads/buyer-lead/create");
  };

  const onEdit = (row) => {
    history.push("/leads/buyer-lead/" + row.id + "/edit");
  };
  const onDelete = (row) => {
    dispatch(deleteBuyerLead(row.id));
  };
  const cancelSubscription = (row) => {
    dispatch(cancelSubscriptionBuyerLead(row.id));
  };

  const otherMarketPlaceOptions = [
    { value: "1", label: "Customer" },
    { value: "2", label: "Lead" },
  ];

  const tableColumns = [
    {
      name: "Customer Name",
      // selector: (row) => row.name,
      cell: (row) => (
        <div>
          <div style={{ fontWeight: "bold" }}>{row.name}</div>
          <div style={{ fontSize: "0.9em", color: "gray", paddingTop: 5 }}>
            {row.vip_validity}
          </div>
        </div>
      ),
      sortable: false,
      left: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      left: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.mobile_number,
      sortable: false,
      left: true,
    },
    {
      name: "WhatsApp Number",
      selector: (row) => row.whatsapp_number,
      sortable: false,
      left: true,
    },
    {
      name: "country",
      selector: (row) => row.country,
      sortable: false,
      left: true,
    },

    {
      name: "Buyer Type",
      selector: (row) => row.buyer_type,
      sortable: false,
      left: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: false,
      left: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {currentUser.permission.includes("Update Buyer Lead") ? (
            <a
              onClick={() => {
                onEdit(row);
              }}
            >
              <i
                className="fa fa-pencil"
                style={{
                  width: 25,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </a>
          ) : (
            ""
          )}

          {currentUser.permission.includes("Delete Buyer Lead") !== -1 ? (
            <a
              onClick={() => {
                onDelete(row);
              }}
            >
              <i
                className="fa fa-trash"
                style={{
                  width: 25,
                  fontSize: 16,
                  padding: 11,
                  color: "red",
                }}
              ></i>
            </a>
          ) : (
            ""
          )}

          {currentUser.permission.includes("Update Buyer Lead") !== -1 ? (
            <a
              onClick={() => {
                cancelSubscription(row);
              }}
            >
              <i
                className="fa fa-window-close-o"
                style={{
                  width: 25,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(208,179,29)",
                }}
              ></i>
            </a>
          ) : (
            ""
          )}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Container fluid={true}>
        {loading ? (
          <>
            <PreLoader></PreLoader>
          </>
        ) : (
          <>
            <Breadcrumb data={dataMenu} title="Buyer Leads" />
            <div className="product-grid">
              <div className="feature-products">
                <Row>
                  <Col sm="12">
                    <Card>
                      <Row>
                        <Col sm="6">
                          <Nav tabs className="border-tab"></Nav>
                        </Col>
                        <Col sm="6">
                          <div className="float-sm-right p-3">
                            <a
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                onAdd();
                              }}
                            >
                              {" "}
                              <i className="fa fa-plus"></i> Add Buyer Lead
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" xl="4">
                    <Form>
                      <FormGroup>
                        <Input
                          defaultValue={buyerLeadState.filter.name}
                          onChange={(e) => {
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              dispatch(
                                setBuyerFilter({
                                  ...buyerLeadState.filter,
                                  name: e.target.value,
                                  currentPage: 1,
                                })
                              );
                            }, 500);
                            setTimer(newTimer);
                          }}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col sm="12" xl="4">
                    <Form>
                      <FormGroup>
                        <Input
                          defaultValue={buyerLeadState.filter.email}
                          onChange={(e) => {
                            dispatch(
                              setBuyerFilter({
                                ...buyerLeadState.filter,
                                email: e.target.value,
                                currentPage: 1,
                              })
                            );
                          }}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Search By Email"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col sm="12" xl="4">
                    <Form>
                      <FormGroup>
                        <Input
                          defaultValue={buyerLeadState.filter.mobile}
                          onChange={(e) => {
                            dispatch(
                              setBuyerFilter({
                                ...buyerLeadState.filter,
                                mobile: e.target.value,
                                currentPage: 1,
                              })
                            );
                          }}
                          name="name"
                          type="number"
                          className="form-control"
                          placeholder="Search By Mobile"
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" xl="4">
                    <FormGroup>
                      <Select
                        placeholder={<div>Buyer Type</div>}
                        className="select2-filter"
                        isClearable
                        styles={customStyles}
                        defaultValue={buyerLeadState.filter.type}
                        options={otherMarketPlaceOptions}
                        onChange={(e) => {
                          dispatch(
                            setBuyerFilter({
                              ...buyerLeadState.filter,
                              type: e ? e : null,
                              currentPage: 1,
                            })
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" xl="4">
                    <FormGroup>
                      <Select
                        placeholder={<div>Country</div>}
                        className="select2-filter"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        // cacheOptions
                        styles={customStyles}
                        defaultValue={buyerLeadState.filter.country}
                        options={buyerLeadState.settings.countries}
                        onChange={(e) => {
                          dispatch(
                            setBuyerFilter({
                              ...buyerLeadState.filter,
                              country: e,
                              currentPage: 1,
                            })
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" xl="4">
                    <FormGroup>
                      <Select
                        placeholder={<div>Customer Representative</div>}
                        className="select2-filter"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        // cacheOptions
                        styles={customStyles}
                        defaultValue={
                          buyerLeadState.filter.customerRepresentative
                        }
                        options={buyerLeadState.settings.customerRepresentative}
                        onChange={(e) => {
                          dispatch(
                            setBuyerFilter({
                              ...buyerLeadState.filter,
                              customerRepresentative: e,
                              currentPage: 1,
                            })
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" xl="4" className="p-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={buyerLeadState.filter.is_vip || false}
                          onChange={(e) => {
                            dispatch(
                              setBuyerFilter({
                                ...buyerLeadState.filter,
                                is_vip: e.target.checked,
                                currentPage: 1,
                              })
                            );
                          }}
                        />
                        VIP Customers
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive product-table">
                          <CommonDataTable
                            headerColumns={tableColumns}
                            gridData={mapBuyerLeadResponseToTable(buyerLead)}
                            onEdit={
                              currentUser.permission.includes(
                                "Update Buyer Lead"
                              )
                                ? onEdit
                                : ""
                            }
                            onDelete={
                              currentUser.permission.includes(
                                "Delete Buyer Lead"
                              )
                                ? onDelete
                                : ""
                            }
                            paginationServer
                            noAction
                            paginationTotalRows={buyerLeadState.buyerTableRow}
                            onChangePage={pageChange}
                            onChangeRowsPerPage={onPageSizeChange}
                            paginationDefaultPage={
                              buyerLeadState.filter.currentPage
                            }
                            paginationPerPage={buyerLeadState.filter.pageSize}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </Container>
    </Fragment>
  );
};

export default withReducer([{ lead: leadReducer }])(BuyerLead);
