import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import SweetAlert from "sweetalert2";

import { mapScanReturnResponseToTable } from "../../../../utils/mapper";
import { approveScanReturn, declineScanReturn } from "../../store/returnOrderSlice";

export const QuantityColumn = ({ item }) => {
    const [tooltipOpenAQ, setTooltipOpenAQ] = useState(false);
    const [tooltipOpenOQ, setTooltipOpenOQ] = useState(false);

    return (
        <>
            {item.ordered_quantity != item.actual_available_quantity ? <>
                <span className="font-weight-bold cursor-default" id={`TooltipItemAvaQty-${item.id}`}>{item.actual_available_quantity}</span>
                <span className="px-2">/</span>
                <span className="font-weight-bold cursor-default" style={{ textDecoration: "line-through", textDecorationColor: "red" }} id={`TooltipItemQty-${item.id}`}>{item.ordered_quantity}</span>
            </> : <>
                <span className="font-weight-bold cursor-default" id={`TooltipItemQty-${item.id}`}>{item.ordered_quantity}</span>
            </>}

            {item.ordered_quantity != item.actual_available_quantity && (
                <Tooltip
                    isOpen={tooltipOpenAQ}
                    toggle={() => setTooltipOpenAQ(!tooltipOpenAQ)}
                    target={`TooltipItemAvaQty-${item.id}`}
                >
                    Available Qty: {item.actual_available_quantity}
                </Tooltip>
            )}

            <Tooltip
                isOpen={tooltipOpenOQ}
                toggle={() => setTooltipOpenOQ(!tooltipOpenOQ)}
                target={`TooltipItemQty-${item.id}`}
            >
                Ordered Qty: {item.ordered_quantity}
            </Tooltip>
        </>
    );
}

const ScanReturnItemList = ({ fillterInputRef, buttonDisabled, setButtonDisabled, setIsAutoReturn, playSuccessSound, playAlertSound }) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const [declineReasonModal, setDeclineReasonModal] = useState(false);
    const declineReasonModalToggle = () => setDeclineReasonModal(!declineReasonModal);

    const orderState = useSelector(({ order }) => order.order);
    const returnState = useSelector(({ order }) => order.returnOrder);
    const scanReturnData = returnState.scanReturnData;
    const [items, setItems] = useState([]);
    const declineReasons = orderState.settings.returnReasonsWarehouse;

    const returnReasonOptions = orderState.settings.returnReasons.map(reason => ({
        value: reason.id,
        label: reason.name
    }));

    const [isShowButtons, setIsShowButtons] = useState(false);
    const [isItemEditable, setIsItemEditable] = useState(false);
    const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
    const [selectedDeclineReason, setSelectedDeclineReason] = useState(null);
    const [approvalButtonDisabled, setApprovalButtonDisabled] = useState(false);
    const [declineButtonDisabled, setDeclineButtonDisabled] = useState(false);

    useEffect(() => {
        let isItemEdit = items ? items.some((item) => item.isEditable) : false;
        setIsItemEditable(isItemEdit);
        setIsShowButtons(isItemEdit);
    }, [items]);

    useEffect(() => {
        let _items = scanReturnData ? mapScanReturnResponseToTable(scanReturnData.items) : [];
        if (_items.length == 1 && _items[0].selected == true && _items[0].quantity == 1 && _items[0].reason_id === null && scanReturnData.default_reason.id != null) {
            _items[0].reason_id = scanReturnData.default_reason.id;
        }
        
        let isQty = _items.some((item) => item.quantity > 0);
        let isSelected = _items.some((item) => item.selected === true);

        setIsAutoReturn(false);
        setItems(_items);

        setTimeout(() => {
            if (_items.length == 1 && _items[0].selected == true && _items[0].quantity == 1) {
                onApprove(_items, true);
            } else if (_items.length > 0 && !isSelected && isQty) {
                setIsAutoReturn(true);
                playAlertSound();
                toast.error("Return autorotation not generated.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }, 300);
    }, [scanReturnData]);

    const handleReason = (row, value) => {
        let itemErrors = row.itemErrors != undefined && row.itemErrors ? row.itemErrors : {};
        itemErrors.isErrorReason = false;
        if (value == '' || value == null) {
            itemErrors.isErrorReason = true;
        }
        itemErrors.isError = itemErrors.isErrorReason || (itemErrors.isErrorQty != undefined && itemErrors.isErrorQty);

        const updatedData = items.map(_row =>
            _row.id === row.id ? {
                ..._row,
                reason_id: value,
                itemErrors: itemErrors
            } : _row
        );
        setItems(updatedData);
    };

    const handleReturnQty = (row, value) => {
        const _value = value ? parseFloat(value) : 0;
        let itemErrors = row.itemErrors != undefined && row.itemErrors ? row.itemErrors : {};
        itemErrors.isErrorQty = false;
        if (_value <= 0 || _value > row.actual_available_quantity || (parseFloat(_value) - parseInt(_value)) != 0) {
            itemErrors.isErrorQty = true;
        }
        itemErrors.isError = itemErrors.isErrorQty || (itemErrors.isErrorReason != undefined && itemErrors.isErrorReason);

        const updatedData = items.map(_row =>
            _row.id === row.id ? {
                ..._row,
                quantity: _value,
                itemErrors: itemErrors
            } : _row
        );
        setItems(updatedData);
    };

    const handleSelectAllRows = (isChecked) => {
        let itemErrors = {
            isError: false,
            isErrorReason: false,
            isErrorQty: false
        };

        const updatedData = items.map(_row => {
            let reason_id = _row.reason_id;
            if (isChecked && _row.reason_id === null && scanReturnData.default_reason.id != "" && scanReturnData.default_reason.id != null) {
                reason_id = scanReturnData.default_reason.id;
            }

            return ((isChecked === true && _row.selected === false && _row.isEditable === true) || isChecked === false) ? {
                ..._row,
                selected: isChecked,
                // quantity: 0,
                reason_id: reason_id,
                itemErrors: { ...itemErrors }
            } : _row;
        });
        setItems(updatedData);
    }

    const handleSelectRow = (row, value) => {
        let itemErrors = {
            isError: false,
            isErrorReason: false,
            isErrorQty: false
        };

        let reason_id = row.reason_id;
        if (value && row.reason_id === null && scanReturnData.default_reason.id != "" && scanReturnData.default_reason.id != null) {
            reason_id = scanReturnData.default_reason.id;
        }

        const updatedData = items.map(_row => {
            return _row.id === row.id ? {
                ..._row,
                selected: value,
                // quantity: 0,
                reason_id: reason_id,
                itemErrors: { ...itemErrors }
            } : _row;
        });
        setItems(updatedData);
    };

    const handleEdit = (rowId, field, value) => {
        const updatedData = items.map(row =>
            row.id === rowId ? { ...row, [field]: value } : row
        );
        setItems(updatedData);
    };

    const formatDateTime = (dateTimeString) => {
        const dateObj = new Date(dateTimeString);
        return `${dateObj.toISOString().split('T')[0]} at ${dateObj.toTimeString().split(' ')[0]}`;
    };

    const displayProductDetailCol = (row) => {
        return (
            <div className='d-flex align-items-center py-2'>
                <img src={row.image_path} alt="Image" className='mr-2' style={{ width: '50px' }} />
                <div>
                    <p className='m-0 text-dark font-weight-700'>{row.product_name}</p>
                    <p className='m-0 text-muted'>{row.sku}</p>

                    {row.return_request_id && (
                        <p className="text-danger">
                            Customer has already raised return request for this item. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/pending-return-requests/${row.return_request_id}/edit`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.made_to_measure_request_id && (
                        <p className="text-danger">
                            Customer has already raised Alteration request for this item. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/alteration-requests/${row.made_to_measure_request_id}/view`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.cancel_request_id && (
                        <p className="text-danger">
                            Customer has already raised cancel request for this item. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/pending-cancel?cancel_request_id=${row.cancel_request_id}`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.refund_request_id && (
                        <p className="text-danger">
                            This item refund request is in the pending section. <br />
                            <a target="_blank" style={{ fontWeight: 'bold' }} href={`${process.env.PUBLIC_URL}/order/return?refund_request_id=${row.refund_request_id}`}>Click here</a> to check that request.
                        </p>
                    )}

                    {row.return_histories && row.return_histories.length > 0 && (
                        row.return_histories.map((return_historie) => (
                            <p className="text-danger m-0" key={return_historie.id}>
                                This item was {return_historie.status} by {return_historie.status_changed_by} on {formatDateTime(return_historie.status_changed_at)} with a quantity of {return_historie.quantity}.
                            </p>
                        ))
                    )}
                </div>
            </div>
        );
    };

    const reasonColumn = (row) => {
        if (row.return_request_id && row.reason) {
            return row.reason;
        }

        let selectOption = returnReasonOptions.find(option => option.value === row.reason_id);

        const customStyles = {
            menuPortal: base => ({ ...base, zIndex: 9999 }), // Ensure the dropdown is above other elements
            control: base => {
                var styles = {
                    ...base,
                    minHeight: '30px', // Adjust the height of the input
                    fontSize: '12px',   // Adjust the font size
                    boxShadow: 'none',
                };
                if (row.itemErrors != undefined && row.itemErrors.isErrorReason != undefined && row.itemErrors.isErrorReason == true) {
                    styles.borderColor = "var(--danger)";
                }
                return styles;
            },
            dropdownIndicator: base => ({
                ...base,
                padding: '4px' // Adjust the padding of the dropdown indicator
            }),
            clearIndicator: base => ({
                ...base,
                padding: '4px' // Adjust the padding of the clear indicator
            }),
            valueContainer: base => ({
                ...base,
                padding: '0px 6px' // Adjust the padding of the value container
            }),
            input: base => ({
                ...base,
                margin: '0px' // Adjust the margin of the input
            })
        };

        return (
            <Select
                placeholder={<div>Reason</div>}
                className="select2-filter w-100"
                isClearable
                cacheOptions
                isDisabled={!row.selected}
                styles={customStyles}
                options={returnReasonOptions}
                value={selectOption}
                onChange={(e) => handleReason(row, e ? e.value : null)}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null
                }}
                menuPortalTarget={document.body} // Render the dropdown menu in the body
            />
        );
    };

    const returnQtyColumn = (row) => {
        let isError = row.itemErrors != undefined && row.itemErrors.isErrorQty != undefined && row.itemErrors.isErrorQty;
        return (
            <input
                type="number"
                className={'form-control form-control-sm text-center' + (isError ? ' input-error' : '')}
                value={row.quantity}
                disabled={!row.selected}
                onChange={(e) => handleEdit(row.id, 'quantity', e.target.value)}
                onBlur={e => handleReturnQty(row, e.target.value)}
                style={{ width: '75px' }}
            />
        );
    };

    const columns = [
        {
            name: <div className={'only-checkbox checkbox' + (isItemEditable ? ' checkbox-primary' : ' checkbox-light')}>
                <input
                    onChange={(e) => {
                        setIsSelectedAllRows(e.target.checked);
                        handleSelectAllRows(e.target.checked);
                    }}
                    type={"checkbox"}
                    checked={isSelectedAllRows}
                    disabled={!isItemEditable}
                    id='selectAllRowsCheckbox' />
                <label htmlFor='selectAllRowsCheckbox'></label>
            </div>,
            selector: row => row.selected,
            cell: (row) => (
                <div className={'only-checkbox checkbox' + (row.isEditable === false ? ' checkbox-light' : ' checkbox-primary')}>
                    <input
                        type="checkbox"
                        checked={row.selected}
                        onChange={e => handleSelectRow(row, e.target.checked)}
                        disabled={row.isEditable === false}
                        id={'selectRowCheckbox' + row.id}
                    />
                    <label htmlFor={'selectRowCheckbox' + row.id}></label>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px',
            // grow: 0.2, // column size
        },
        {
            name: 'Product',
            selector: row => row.name,
            cell: row => displayProductDetailCol(row),
            grow: 2, // column size
        },
        {
            name: 'Quantity',
            selector: row => row.ordered_quantity,
            cell: row => <QuantityColumn item={row} />,
            center: true,
            grow: 0.5, // column size
        },
        {
            name: <><strong>Return Qty</strong> <span className='text-danger ml-1'>*</span></>,
            selector: row => returnQtyColumn(row),
            center: true,
            grow: 0.5, // column size
        },
        {
            name: <><strong>Reason</strong> <span className='text-danger ml-1'>*</span></>,
            selector: row => row.reason_id,
            cell: row => reasonColumn(row),
            left: true,
            grow: 1, // column size
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.selected == true && !(row.itemErrors != undefined && row.itemErrors.isError != undefined && row.itemErrors.isError == true),
            style: {
                backgroundColor: 'rgba(206, 240, 253, 0.9)',
            },
        },
        {
            when: row => row.selected == true && (row.itemErrors != undefined && row.itemErrors.isError != undefined && row.itemErrors.isError == true),
            style: {
                backgroundColor: 'rgba(253, 206, 206, 0.9)',
            },
        },
    ];

    const handleApprove = () => {
        onApprove(items, true);
    }

    const onApprove = (orderItems, isAutoApproval = false) => {
        let isError = false;
        let returnItems = [];
        let errorMessage = '';

        const updatedItems = orderItems.map(item => {
            let itemErrors = {
                isError: false,
                isErrorReason: false,
                isErrorQty: false
            };

            if (item.selected === true) {
                if (item.reason_id === null) itemErrors.isErrorReason = true;
                if (parseFloat(item.quantity) === 0) itemErrors.isErrorQty = true;
                if (parseFloat(item.quantity) > parseFloat(item.actual_available_quantity)) itemErrors.isErrorQty = true;

                returnItems.push({
                    line_item_id: item.order_line_item_id,
                    quantity: item.quantity,
                    reason_id: item.reason_id
                });
            }

            itemErrors.isError = itemErrors.isErrorReason || itemErrors.isErrorQty;

            if (itemErrors.isError) isError = true;

            return { ...item, itemErrors: itemErrors };
        });

        setItems(updatedItems);

        if (isError || returnItems.length == 0) {
            toast.error("Invalid submission, please check all required fields", {
                position: toast.POSITION.TOP_RIGHT,
            });

            return;
        }

        if (isAutoApproval) {
            itemReturnAndRefund(returnItems);
        } else {
            itemReturnAndRefundConfirmModal(returnItems);
        }
    }

    const itemReturnAndRefundConfirmModal = (refundItems) => {
        SweetAlert.fire({
            title: 'Are you sure you want to approve?',
            text: "Once confirmed, the order will be refunded in the system.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Approve',
            cancelButtonText: 'No, Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                itemReturnAndRefund(refundItems);
            }
        });
    }

    const itemReturnAndRefund = (refundItems) => {
        setButtonDisabled(true);
        setApprovalButtonDisabled(true);
        let data = {
            'order_id': scanReturnData.order_id,
            'items': refundItems
        };
        dispatch(approveScanReturn(data)).then(res => {
            if (!res.error) {
                playSuccessSound();
            } else {
                playAlertSound();
            }
            setButtonDisabled(false);
            setApprovalButtonDisabled(false);
            fillterInputRef.current.focus();
        });
    }

    const declineRequest = (declineItems) => {
        setButtonDisabled(true);
        setDeclineButtonDisabled(true);
        let data = {
            'order_id': scanReturnData.order_id,
            'reason_id': selectedDeclineReason,
            'items': declineItems
        };
        dispatch(declineScanReturn(data)).then(res => {
            if (!res.error) {
                playSuccessSound();
                declineReasonModalToggle();
            } else {
                playAlertSound();
            }
            setButtonDisabled(false);
            setDeclineButtonDisabled(false);
            setSelectedDeclineReason(null);
            fillterInputRef.current.focus();
        });
    };

    const handleDecline = () => {
        let isError = false;
        let returnItems = [];
        let errorMessage = '';

        const updatedItems = items.map(item => {
            let itemErrors = {
                isError: false,
                isErrorReason: false,
                isErrorQty: false
            };

            if (item.selected === true) {
                if (item.reason_id === null) itemErrors.isErrorReason = true;
                if (parseFloat(item.quantity) === 0) itemErrors.isErrorQty = true;
                if (parseFloat(item.quantity) > parseFloat(item.actual_available_quantity)) itemErrors.isErrorQty = true;

                returnItems.push({
                    line_item_id: item.order_line_item_id,
                    quantity: item.quantity,
                    reason_id: item.reason_id
                });
            }

            itemErrors.isError = itemErrors.isErrorReason || itemErrors.isErrorQty;

            if (itemErrors.isError) isError = true;

            return { ...item, itemErrors: itemErrors };
        });

        setItems(updatedItems);

        if (isError || returnItems.length == 0) {
            toast.error("Invalid submission, please check all required fields", {
                position: toast.POSITION.TOP_RIGHT,
            });

            return;
        }

        declineReasonModalToggle(); // Open decline reason modal
    }

    const submitDeclineForm = () => {
        if (selectedDeclineReason === null) {
            toast.error("Please select a reason for declining", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        let returnItems = [];
        for (var item of items) {
            if (item.selected === true) {
                returnItems.push({
                    line_item_id: item.order_line_item_id,
                    quantity: item.quantity,
                    reason_id: item.reason_id
                });
            }
        }
        declineRequest(returnItems);
    }

    const handleDeclineReason = (reasonId) => {
        setSelectedDeclineReason(reasonId);
    }

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip h5 ribbon-dark">
                    {items.length > 1 ? 'Order Items ' : 'Order Item '}
                    {scanReturnData.order_name}
                </div>

                <DataTable
                    columns={columns}
                    data={items}
                    selectableRows={false}
                    highlightOnHover
                    conditionalRowStyles={conditionalRowStyles}
                />

                {isShowButtons && (
                    <Row className="mt-3">
                        <Col md={12} className="text-right">
                            <Button
                                color="danger"
                                onClick={handleDecline}
                                disabled={buttonDisabled || declineButtonDisabled}
                                className="btn btn-success mr-2">
                                {declineButtonDisabled && <i className="fa fa-spinner spinner mr-1" />} Decline
                            </Button>
                            <Button
                                color="success"
                                onClick={handleApprove}
                                disabled={buttonDisabled || approvalButtonDisabled}
                                className="btn btn-success">
                                {approvalButtonDisabled && <i className="fa fa-spinner spinner mr-1" />} Approve
                            </Button>
                        </Col>
                    </Row>
                )}

                <Modal isOpen={declineReasonModal} size="xl">
                    <ModalHeader toggle={declineReasonModalToggle}>
                        Return Desclined Reasons
                    </ModalHeader>
                    <ModalBody>
                        <p>Are you sure want to decline? Please select a reason for declining?</p>
                        <Row>
                            <Col md={12}>
                                {declineReasons.map((reason, index) => (
                                    <Button
                                        key={index}
                                        outline={selectedDeclineReason != reason.id}
                                        color="primary"
                                        className="mr-2 mb-2"
                                        onClick={() => {
                                            handleDeclineReason(reason.id);
                                        }}>
                                        {reason.name}
                                    </Button>
                                ))}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={declineReasonModalToggle} disabled={buttonDisabled}>Cancel</Button>
                        <Button color="primary" onClick={submitDeclineForm} disabled={buttonDisabled}>Submit</Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
        </Card>
    );
}

export default (ScanReturnItemList)