import React, { Fragment, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Popover,
    PopoverBody,
    Row,
    ButtonGroup, Button
} from "reactstrap";

import { useHistory } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DateRangePicker } from 'rsuite';

import Breadcrumb from "../../../../layout/breadcrumb";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import { mapSearchAutoSuggestion } from "../../../../utils/mapper";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../../store/withReducer";
import sellerReducer from "../../store";
import { sellerAutoSuggestionApi } from "../../../../services/manage-product/productService";
import { agentAutoSuggestionApi } from "../../../../services/seller";
import DateRangePickerPrimary from "../../../../share-components/ui/DateRangePickerPrimary";
import MonthPicker from "../../../../share-components/ui/MonthPicker";

import {
    downloadAdminCommissionExcel,
    downloadAdminCommissionPdf,
    EmailAdminCommissionExport,
    getSellerSettings,
    setSettings
} from "../../store/sellerSlice";

import { getCommissions } from "../../store/sellerCommissionSlice";

const { afterToday } = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const SellerCommission = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    const todayDate = new Date();
    const sevenDays = new Date().setDate(todayDate.getDate() - 7);
    
    const [dateRange, setDateRange] = useState([sevenDays, todayDate]);
    const [startDate, endDate] = dateRange;

    const [month, setMonth] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1));

    const authData = useSelector(({ authReducer }) => authReducer);
    const [seller, setSeller] = useState(authData.defaultSeller);
    const userState = useSelector(({ authReducer }) => authReducer.data);
    const sellerState = useSelector(({ seller }) => seller.seller);
    const sellerCommission = useSelector(({ seller }) => seller.sellerCommission);

    const [agent, setAgent] = useState(null);
    const [loading, isSetLoading] = useState(false);
    const [dateType, setDateType] = useState('month');

    const dataMenu = [
        {
            type: 0,
            text: "Seller Commission"
        }
    ];

    // Function to disable dates before 1 month ago
    const disabledMonth = (date) => {
        const now = new Date();
        now.setMonth(now.getMonth() - 1); // Move back 1 month
    
        return date.getFullYear() > now.getFullYear() || 
               (date.getFullYear() === now.getFullYear() && date.getMonth() > now.getMonth());
    };

    const tableColumns = [
        {
            name: 'Seller Name',
            selector: row => row.name,
            sortable: false,
            center: false,
        },
        {
            name: 'Total Amount (inc Vat) (£)',
            selector: row => row.total_net_amount,
            sortable: false,
            right: true,
        },
        {
            name: 'Total Vat (£)',
            selector: row => row.total_vat_amount,
            sortable: false,
            right: true,
        },
        {
            name: 'Total Amount (exc Vat) (£)',
            selector: row => row.total_net_amount_exc_vat,
            sortable: false,
            right: true,
        },
        {
            name: 'Commission (inc Vat) (£)',
            selector: row => row.total_commission_amount_inc_vat,
            sortable: false,
            right: true,
        },
        {
            name: 'Commission (£)',
            selector: row => row.total_commission_vat_amount,
            sortable: false,
            right: true,
        },
        {
            name: 'Commission (exc Vat) (£)',
            selector: row => row.total_commission_amount_exc_vat,
            sortable: false,
            right: true,
        },
        {
            name: 'Agent Commission (£)',
            selector: row => row.agent_commission,
            sortable: false,
            right: true,
        },
        {
            name: 'Action',
            selector: row => (
                <div>
                    {row.is_created_xero && <>                        
                    <a className={(loading ? "disabled" : "") + " cursor-pointer"} onClick={() => { onAdminDownloadPDF(row); }}>
                        <i className="fa fa-file-pdf-o" style={{ fontSize: 16, color: 'rgb(226,203,47)' }}></i>
                    </a>
                    <a className={(loading ? "disabled" : "") + " cursor-pointer ml-3"} onClick={() => { onAdminEmail(row); }}>
                        <i className="fa fa-envelope-o" style={{ fontSize: 16, color: 'rgb(226,68,47)' }}></i>
                    </a>
                    </>}
                    <a className={(loading ? "disabled" : "") + " cursor-pointer ml-3"} onClick={() => { onAdminDownloadExcel(row); }}>
                        <i className="fa fa-file-excel-o" style={{ fontSize: 16, color: 'rgb(40, 167, 69)' }}></i>
                    </a>
                    <a className={(loading ? "disabled" : "") + " cursor-pointer ml-3"} onClick={() => { onAdminCommissionView(row); }}>
                        <i className="fa fa-eye" style={{ fontSize: 16, color: 'rgb(112,71,64)' }}></i>
                    </a>

                    {row.loading && (
                        <a>
                            <i className="fa fa-spinner fa-spin fa-lg spinner ml-3" style={{ fontSize: 16 }}></i>
                        </a>
                    )}
                </div>
            ),
            sortable: false,
            right: true,
        }
    ];

    useEffect(() => {
        setSeller(authData.defaultSeller)
    }, [authData.defaultSeller])

    const onAdminDownloadExcel = (row) => {
        const data = {
            seller_id: row.id,
            start_date: moment(sellerCommission.sellerCommissionStartDate).format('YYYY-MM-DD'),
            end_date: moment(sellerCommission.sellerCommissionEndDate).format('YYYY-MM-DD'),
        }
        isSetLoading(true);
        dispatch(downloadAdminCommissionExcel(data)).then(res => {
            const link = document.createElement("a");
            link.href = res.payload;
            link.setAttribute("download", "");
            document.body.appendChild(link);
            link.click();
            link.remove();
            isSetLoading(false);
        });
    }

    const onAdminDownloadPDF = (row) => {
        const data = {
            seller_id: row.id,
            start_date: moment(sellerCommission.sellerCommissionStartDate).format('YYYY-MM-DD'),
            end_date: moment(sellerCommission.sellerCommissionEndDate).format('YYYY-MM-DD'),
        }
        isSetLoading(true);
        dispatch(downloadAdminCommissionPdf(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${row.name}_${(new Date().toJSON().slice(0, 10))}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            isSetLoading(false);
        });
    }

    const onAdminEmail = (row) => {
        const data = {
            seller_id: row.id,
            start_date: moment(sellerCommission.sellerCommissionStartDate).format('YYYY-MM-DD'),
            end_date: moment(sellerCommission.sellerCommissionEndDate).format('YYYY-MM-DD'),
        }
        isSetLoading(true);
        dispatch(EmailAdminCommissionExport(data)).then(res => {
            isSetLoading(false);
        });
    }

    const onAdminCommissionView = (row) => {
        const data = {
            seller_id: row.id,
            seller_name: row.seller_name,
            start_date: moment(sellerCommission.sellerCommissionStartDate).format('YYYY-MM-DD'),
            end_date: moment(sellerCommission.sellerCommissionEndDate).format('YYYY-MM-DD'),
        }

        history.push(`/commission/detail-commission?seller=${data.seller_id}&seller_name=${data.seller_name}&start_date=${data.start_date}&end_date=${data.end_date}`);
    }

    useEffect(() => {
        dispatch(getSellerSettings()).then(res => {
            if (!res.error) {
                const results = {
                    settings: res.payload,
                    id: null
                }
                dispatch(setSettings(results));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getData();
    }, [page, month, startDate, endDate, seller, agent, dateType]);

    const getData = () => {
        let data = {
            page: page,
            pageSize: 10,
            seller_id: seller && seller.value ? seller.value : '',
            agent_id: agent,
        }

        if (dateType == 'month') {
            data.month_date = moment(month).format('YYYY-MM-DD');
        }else {
            data.start_date = moment(startDate).format('YYYY-MM-DD');
            data.end_date = moment(endDate).format('YYYY-MM-DD');
        }

        isSetLoading(true);
        dispatch(getCommissions({ ...data })).then(res => {
            if (!res.error) {
                isSetLoading(false);
            }
        });
    }

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });

    const agentPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(agentAutoSuggestionApi(data)))
            });
        });

    const onDateType = (type) => {
        setDateType(type)
    }

    return (
        <Fragment>
            <Breadcrumb title="Seller Commission" data={dataMenu} />
            <Container fluid={true} className="product-wrapper">
                <div className="product-grid mb-2">
                    <div className="feature-products">
                        <Row>
                            <Col sm="12" md="5" className="mb-3">
                                <Row>
                                    <Col md="4">
                                        <ButtonGroup>
                                            <Button outline={!(dateType == 'month')} color="dark" onClick={() => {onDateType('month')}}>Month</Button>
                                            <Button outline={!(dateType == 'date')} color="dark" onClick={() => {onDateType('date')}}>Date</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col md="" className="pl-0">
                                        {dateType == 'month' && (
                                            <MonthPicker
                                                dateChange={(date)=>{
                                                    setMonth(date)
                                                }}
                                                defaultValue={month}
                                                disabledDate={disabledMonth}
                                            />
                                        )}

                                        {dateType == 'date' && (                                            
                                            <DateRangePickerPrimary
                                                dateRange={dateRange}
                                                dateRangeChange={(date) => {
                                                    setDateRange(date)
                                                }}
                                                defaultValue={[todayDate, sevenDays]}
                                                disabledDate={afterToday()}
                                            />
                                        )}

                                    </Col>
                                </Row>                                
                            </Col>
                            <Col sm="12" md="" className="mb-3">
                                <AsyncSelect
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    styles={customStyles}
                                    placeholder="Select Seller"
                                    loadOptions={sellerPromiseOption}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    onClear={(e) => {

                                    }}
                                    value={seller}
                                    onChange={(e) => {
                                        setSeller(e);
                                        // setSeller(e?e.value:null);
                                    }}

                                />
                            </Col>
                            <Col sm="12" md="" className="mb-3">
                                {((userState.user.role.name !== "Seller") || (userState.user.is_primary_seller)) && <Select
                                    className="select2-filter"
                                    isClearable={true}
                                    defaultOptions
                                    placeholder="Select Agent / Sales Person"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    cacheOptions
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={customStyles}
                                    options={sellerState.settings.agents}
                                    onChange={(e) => {
                                        setAgent(e ? e.id : null)
                                    }}

                                />}
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <CommonDataTable
                                        headerColumns={tableColumns}
                                        gridData={sellerCommission.sellerCommissionLists}
                                        noAction
                                        paginationServer
                                        paginationTotalRows={sellerCommission.sellerCommissionTableRow}
                                        paginationPerPage={10}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPage(page)}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withReducer([{ seller: sellerReducer }])(SellerCommission);