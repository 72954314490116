import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, Row, Col, Container, Table, Button } from "reactstrap";

const ProgressBox = ({ event, isShowBar = true }) => {
    return (
        <>
            <div className="retrun-progress-box">
                <p className="p-0 m-0 text-dark font-weight-bolder">{event.event_name}</p>
                <p className="p-0 m-0 text-dark">{event.event_date_time}</p>
                <p className="p-0 m-0 text-dark">{event.location_name}</p>
            </div>
        </>
    );
}

const ReturnProcessTimeline = (props) => {
    const { trackingSummary } = props;
    const events = trackingSummary.events;

    return (
        <Fragment>
            <div className="retrun-progress-timeline">
                {events.map((event, index) => (
                    <ProgressBox key={'ps-title-' + event.id} event={event} isShowBar={index != 0}></ProgressBox>
                ))}
            </div>
        </Fragment>
    );

}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.orderState) == JSON.stringify(nextProps.orderState);
}

export default React.memo(ReturnProcessTimeline, areEqual);