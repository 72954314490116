import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';

import {
    addTrackingItems,
    closeNewInvoiceDialog, closeNewTrackingDialog, createTrackingInvoice, downloadOrderInvoice, downloadTrackingInvoice,
    openNewInvoiceDialog, openNewTrackingDialog,
    setOrderData,

    setSettings,
    showOrder, showTrackingInvoice
} from "../store/orderSlice";
import { useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import orderReducer from "../store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { toast } from "react-toastify";
import { Details } from "./content/details";
import ModalFormSubmit from "../../../share-components/form/modalFormSubmit";
import SettingForm from "../../manage-product/product-setting/settingForm";
import RoleForm from "../../manage-user/role/roleForm";
import { openNewDialog } from "../../manage-user/store/roleSlice";
import InvoiceForm from "./invoice/create";
import TrackingForm from "./create";
import PreLoader from "../../../share-components/ui/preLoader";

export const defaultValues = {
    invoiceDate: new Date(),
};

export const schema = yup.object().shape({
    invoiceDate: yup.date().required('Invoice Date is required field'),
});

export const defaultValuesTracking = {
    deliver_partner: '',
    tracking_id: '',
    tracking_url: '',
    only_update: false
};
// const urlValidation = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})";
const urlValidation = /^(https?:\/\/)/;

export const schemaTracking = yup.object().shape({
    tracking_id: yup.string().required('Tracking ID is required field').typeError('Tracking ID is required field'),
    tracking_url: yup.string().matches(urlValidation, "Entered URL is invalid").required('Tracking URL is required field').typeError('Tracking URL is required field'),
    deliver_partner: yup.object().required('Deliver Partner is required field').typeError('Deliver Partner is required field'),
});
const AddTracking = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const [productVariants, setProductVariants] = useState([]);
    const [loading, isSetLoading] = useState(true);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const orderState = useSelector(({ order }) => order.order);
    useEffect(() => {
        dispatch(showTrackingInvoice(id)).then(res => {
            if (!res.error) {
                dispatch(setOrderData(res.payload));
                isSetLoading(false);
            }
        });
    }, [dispatch]);

    const dataMenu = [
        {
            type: 1,
            text: 'Orders',
            link: '/order'
        },
        {
            type: 0,
            text: "Add Tracking"
        }
    ];

    const { handleSubmit, formState, reset, register, setValue, getValues, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const onSubmitForm = (dialog, data) => {
        if (productVariants.length !== 0) {
            setBtnDisabled(true);
            const set = {
                "invoiceDate": `${data.invoiceDate.getFullYear()}-${(data.invoiceDate.getMonth() + 1) < 2 ? '0' + (data.invoiceDate.getMonth() + 1) : (data.invoiceDate.getMonth() + 1)}-${data.invoiceDate.getDate() < 2 ? '0' + data.invoiceDate.getDate() : data.invoiceDate.getDate()}`,
                "orderId": id,
                "items": productVariants
            }

            dispatch(createTrackingInvoice(set)).then(res => {
                if (!res.error) {
                    setProductVariants([]);
                    onAddInvoiceClose();
                    dispatch(setOrderData(res.payload));
                    setBtnDisabled(false);
                }
            });
        } else {
            toast.error("At least one create is required to be selected", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const { handleSubmit: handleSubmitTracking, formState: formStateTracking, reset: resetTracking, register: registerTracking, setValue: setValues, getValues: getValuesTracking, trigger: triggers } = useForm({
        mode: 'onChange',
        defaultValues: defaultValuesTracking,
        resolver: yupResolver(schemaTracking)
    });
    const { errors: errorsTracking } = formStateTracking;
    const onSubmitFormTracking = (dialog, data) => {
        const set = {
            "trackingID": data.tracking_id,
            "trackingUrl": data.tracking_url,
            "invoiceID": data.id,
            "trackingName": data.deliver_partner.name,
            "fulfillment_status": data.status,
            "only_update": data.only_update
        }
        setBtnDisabled(true);
        dispatch(addTrackingItems(set)).then(res => {
            setBtnDisabled(false);
            if (res.error) {
                console.log(res.error);
                setErrorMessage(res.error.message ? res.error.message : null);
            }else {                
                onAddTrackingClose();
                setErrorMessage(null);
                dispatch(setOrderData(res.payload));
            }
        });
    }

    const onAddInvoiceClose = () => {
        reset(defaultValues);
        dispatch(closeNewInvoiceDialog());
    }

    const onAddTrackingOpen = (invoice) => {
        const deliverPartners = orderState.deliverPartner ? orderState.deliverPartner.filter(data => data.name === invoice.tracking_name) : [];
        const deliverPartner = deliverPartners.length > 0 ? deliverPartners[0] : '';
        const _invoice = { ...invoice, ...{ deliver_partner: deliverPartner, only_update: false } };
        dispatch(openNewTrackingDialog(_invoice));
    }

    const onAddTrackingClose = () => {
        setErrorMessage(null);
        resetTracking(defaultValuesTracking);
        dispatch(closeNewTrackingDialog());
    }

    const handleTrackingInvoiceDownload = (id, invoiceNo) => {
        dispatch(downloadTrackingInvoice(id)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${invoiceNo}_Tracking_Invoice_${(new Date().toJSON().slice(0, 10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    
    return (
        <Fragment>
            {loading ? <>
                <PreLoader></PreLoader>
            </> : <>
                <Breadcrumb title="Orders" data={dataMenu} state='submit' />
                <Container fluid={true} className="product-wrapper">
                    {orderState.list ? orderState.list.length > 0 && (<div className="product-grid">
                        <div className="feature-products">
                            <Card>
                                <Row>
                                    <Col sm="12">
                                        <div className='float-sm-left p-3'>
                                            <h5>Tracking for {orderState.data ? orderState.data.order_name : ""}</h5>
                                        </div>
                                        <div className='float-sm-right p-3'>
                                            <a onClick={() => { dispatch(openNewInvoiceDialog()) }} className="btn btn-sm btn-primary"> <i className="fa fa-plus" /> Create Tracking Invoice</a>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>) : ""}

                    {orderState.trackingDetails ? orderState.trackingDetails.length > 0 && (
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <div className="ribbon ribbon-clip h5 ribbon-dark">Invoices for Order {orderState.data ? orderState.data.order_name : "-"}</div>
                                        {orderState.data && orderState.data.risk_level !== "LOW" && (
                                            orderState.data.fulfillment_status != "fulfilled" ? (
                                                <div className="text-danger mt-4">
                                                    <strong>Note:</strong> This order has been flagged as possibly fraudulent. Please do not process and contact customer service at <a href="mailto:info@goddiva.co.uk">info@goddiva.co.uk </a> as soon as possible.
                                                </div>
                                            ) : null
                                        )}
                                        <Details orderState={orderState} onAddTrackingOpen={onAddTrackingOpen} handleTrackingInvoiceDownload={handleTrackingInvoiceDownload} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : ""}

                </Container>
            </>}

            <ModalFormSubmit
                index={1}
                defaultValues={defaultValues}
                schema={schema}
                onSubmitForm={onSubmitForm}
                reset={reset}
                btnDisabled={btnDisabled}
                loadingBtn
                size="lg"
                formState={formState}
                handleSubmit={handleSubmit}
                title='Invoice'
                selector='order'
                dialog={({ order }) => order.order.newInvoiceDialog}
                closeDialog={onAddInvoiceClose}
            >
                <InvoiceForm productVariants={productVariants} setProductVariants={setProductVariants} orderState={orderState} errors={errors} setValue={setValue} trigger={trigger} />
            </ModalFormSubmit>

            <ModalFormSubmit
                index={2}
                defaultValues={defaultValuesTracking}
                schema={schemaTracking}
                onSubmitForm={onSubmitFormTracking}
                reset={resetTracking}
                formState={formStateTracking}
                handleSubmit={handleSubmitTracking}
                btnDisabled={btnDisabled}
                loadingBtn
                title='Tracking'
                selector='order'
                dialog={({ order }) => order.order.addTrackingDialog}
                closeDialog={onAddTrackingClose}
            >
                <TrackingForm orderState={orderState} trigger={triggers} errors={errorsTracking} register={registerTracking} setValue={setValues} getValues={getValuesTracking} errorMessage={errorMessage} />
            </ModalFormSubmit>
        </Fragment>
    );
}

export default withReducer([{ order: orderReducer }])(AddTracking);